/**
 * Verhindert einfache html Injections
 * @param {*} htmlString
 * @returns sicherer html string
 */
 function secureHTML(htmlString) {
	if (!htmlString) {
		return '';
	}
	return htmlString.toString()
		.replace(/</g, '&lt;')
		.replace(/>/g, '&gt;');
}

/**
 * Sucht ein Mitarbeiterobjekt mit der MitarbeiterID
 * @param {int} MitarbeiterID
 * @returns mitarbeiter
 */
function findeMitarbeiter(mitarbeiter, MitarbeiterID) {
	try {
		const result = mitarbeiter.find((m) => m._id === MitarbeiterID);
		if (result === undefined) {
			return {};
		}
		return result;
	} catch (error) {
		console.log(error);
		return {};
	}
}

/**
 * Sucht eine Qualifikationobjekt mit der QualifikationID
 * @param {string} QualifikationID
 * @returns qualifikation
 */
function findeQualifikation(qualifikationen, QualifikationID) {
	try {
		const result = qualifikationen.find((q) => q._id === QualifikationID);
		if (result === undefined) {
			return {};
		}
		return result;
	} catch (error) {
		console.log(error);
		return {};
	}
}

/**
 * Sucht ein Postensobjekt mit der PostenID
 * @param {string} PostenID
 * @returns posten
 */
function findePosten(postenListe, PostenID) {
	try {
		const result = postenListe.find((b) => b._id === PostenID);
		if (result === undefined) {
			return {};
		}
		return result;
	} catch (error) {
		console.log(error);
		return {};
	}
}

/**
 * Filtert das Schichten Array nach MitarbeiterID und gibt nur Schichten des Mitarbeiters zurück
 * @param {array<object>} schichten
 * @param {string} mitarbeiterID
 * @param {string} postenID
 * @returns sortieres alleSchichten Array
 */
function filterSchichtenMitarbeiter(schichten, mitarbeiterID, postenID) {
	try {
		const result = schichten.filter((s) => s.MitarbeiterID === mitarbeiterID && s.PostenID === postenID);
		if (result === undefined) {
			return {};
		}
		return result;
	} catch (error) {
		console.log(error);
		return {};
	}
}

// Konvertiert eine Zahl in das Euro Format.
function convertNumberToEuro(value) {
	return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value);
}
// Konvertiert eine Zahl in das Euro Format.
function convertNumberDE(value) {
	return new Intl.NumberFormat('de-DE').format(value);
}

// Debounce, Funktionsaufruf (cb) wird erst getätigt nacht einem bestimmten delay, z.B. 1 Sekunden
function debounce(cb, delay = 1000) {
    let timeout;
    return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            cb(args);
        }, delay);
    };
}

export {
    secureHTML,
    findeMitarbeiter,
    findeQualifikation,
    findePosten,
    filterSchichtenMitarbeiter,
	convertNumberToEuro,
	convertNumberDE,
	debounce
};
