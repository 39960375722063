import dayjs from './dayjs.js';

export class CalenderService extends EventTarget {
    constructor(htmlParent, datum = new Date(), eventHandler = {}, options = { range: false }) {
        super();
        const heute = dayjs(datum);
        this.date = heute;
        this.month = heute.month();
        this.year = heute.year();
        this.isoWeek = heute.isoWeek();
        this.htmlParent = htmlParent;
        this.datumEventHandler = eventHandler;
        this.options = options;
        // init global values
        if (this.options.range) {
            window.myVars = window.myVars || {};
            window.myVars.dateIteration = 0;
            window.myVars.dateVon = this.date;
            window.myVars.dateBis = this.date;
        }
        this.renderCalendar();
        if (this.datumEventHandler) {
            this.datumEventHandler(this.date);
        }
        this.init = true;
    }

    get monat() {
        return this.month;
    }

    get jahr() {
        return this.year;
    }

    get datumJS() {
        return this.date.toDate();
    }

    get datumDayjs() {
        return this.date.clone();
    }

    fireEvent(value) {
        const event = new CustomEvent('render', { detail: value });
        this.dispatchEvent(event);
    }

    /**
     * @param {dayjs} datum
     */
    setDatum(datum, monthChange = false) {
        this.date = dayjs(datum);
        this.month = this.date.month();
        this.year = this.date.year();
        this.isoWeek = this.date.isoWeek();
        this.renderCalendar(monthChange);
        this.fireEvent(this.date);
        if (!monthChange && this.options.range) {
            if (window.myVars.dateIteration % 2 === 0) {
                window.myVars.dateVon = this.date;
                window.myVars.dateBis = '';
            } else {
                window.myVars.dateBis = this.date;
                // Wenn das bis datum, das von datum unterschreitet setzen wir das bis datum zurück...
                if (window.myVars.dateBis.isBefore(window.myVars.dateVon, 'day')) {
                    window.myVars.dateVon = this.date;
                    window.myVars.dateBis = '';
                    window.myVars.dateIteration--;
                }
            }
            window.myVars.dateIteration++;
        }
        if (this.datumEventHandler) {
            this.datumEventHandler(this.date);
        }
    }

    changeMonth(monthDiff) {
        const newDate = this.date.add(monthDiff, 'months');
        this.setDatum(newDate, true);
    }

    getDatumString(format = 'YYYY-MM-DD') {
        return this.date.format(format);
    }

    getSelectedDateHTML() {
        return this.htmlParent.querySelector('.cal-date-selected');
    }

    registerDatumEventHandler(handler) {
        this.datumEventHandler = handler;
    }

    renderCalendar(monthChange = false) {
        if (!this.init) {
            this.htmlParent.insertAdjacentHTML('beforeend', this._calenderTemplateHTML());
            this.htmlParent.querySelector('.cal-month-prev').addEventListener('click', () => this.changeMonth(-1));
            this.htmlParent.querySelector('.cal-month-next').addEventListener('click', () => this.changeMonth(1));
        }
        // render cal header
        this.htmlParent.querySelector('.cal-month').innerText = this.getDatumString('MMMM YYYY');
        // render cal dates
        const startDate = this.date.startOf('month').startOf('week');
        const endDate = this.date.endOf('month').endOf('week');
        const days = endDate.diff(startDate, 'day') + 1;
        // remove previous content, this includes event handlers
        const datesHTML = this.htmlParent.querySelectorAll('.cal-date');
        datesHTML.forEach((date) => date.remove());
        const calBody = this.htmlParent.querySelector('.cal-body');
        for (let d = 0; d < days; d++) {
            const tmpDate = startDate.add(d, 'days');
            const dateHTML = document.createElement('div');
            dateHTML.className = 'cal-date';
            dateHTML.innerText = tmpDate.date();
            dateHTML.setAttribute('aria-label', tmpDate.format('YYYY-MM-DD'));
            tmpDate.month() !== this.month ? dateHTML.classList.add('cal-date-other') : dateHTML.classList.remove('cal-date-other');
            dateHTML.addEventListener('click', () => {
                dateHTML.classList.add('cal-date-selected');
                this.setDatum(tmpDate);
            });
            // ausgewählten Tag markieren
            if (tmpDate.isSame(this.date, 'day') && (!monthChange && !this.options.range)) {
                dateHTML.classList.add('cal-date-selected');
            }
            // heutigen Tag umranden
            if (tmpDate.isSame(dayjs(), 'day')) {
                dateHTML.classList.add('cal-date-today');
                dateHTML.title = 'heute';
            }
            calBody.appendChild(dateHTML);
        }

    }

    _calenderTemplateHTML() {
        return `<div class="cal-header">
                <i class="bi bi-arrow-left-circle cal-month-prev"></i>
                <span class="cal-month">April 2024</span>
                <i class="bi bi-arrow-right-circle cal-month-next"></i>
            </div>
            <div class="cal-body">
                <div class="cal-tag">Mo</div>
                <div class="cal-tag">Di</div>
                <div class="cal-tag">Mi</div>
                <div class="cal-tag">Do</div>
                <div class="cal-tag">Fr</div>
                <div class="cal-tag">Sa</div>
                <div class="cal-tag">So</div>
                <div class="cal-date"></div>
                <div class="cal-date"></div>
                <div class="cal-date"></div>
                <div class="cal-date"></div>
                <div class="cal-date"></div>
                <div class="cal-date"></div>
                <div class="cal-date"></div>
                <div class="cal-date"></div>
                <div class="cal-date"></div>
                <div class="cal-date"></div>
                <div class="cal-date"></div>
                <div class="cal-date"></div>
                <div class="cal-date"></div>
                <div class="cal-date"></div>
                <div class="cal-date"></div>
                <div class="cal-date"></div>
                <div class="cal-date"></div>
                <div class="cal-date"></div>
                <div class="cal-date"></div>
                <div class="cal-date"></div>
                <div class="cal-date"></div>
                <div class="cal-date"></div>
                <div class="cal-date"></div>
                <div class="cal-date"></div>
                <div class="cal-date"></div>
                <div class="cal-date"></div>
                <div class="cal-date"></div>
                <div class="cal-date"></div>
                <div class="cal-date"></div>
                <div class="cal-date"></div>
                <div class="cal-date"></div>
                <div class="cal-date"></div>
                <div class="cal-date"></div>
                <div class="cal-date"></div>
                <div class="cal-date"></div>
                <div class="cal-date"></div>
                <div class="cal-date"></div>
                <div class="cal-date"></div>
                <div class="cal-date"></div>
                <div class="cal-date"></div>
                <div class="cal-date"></div>
                <div class="cal-date"></div>
            </div>`;
    }
}