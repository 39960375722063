import _ from 'underscore';
import dayjs from '../../../../shared/services/dayjs.js';
import systemNachrichtService from './systemNachrichtService.js';
import SystemDialogService from '../../../../shared/services/systemDialogService.js';
import { getCurrentSortListe } from '../util.js';
import { berichteTypen } from '../berichteTypen.js';

class StammdatenService {
    constructor() {
        this.unternehmen = undefined;
        this.aktuelleBS = undefined;
        this.aktuellerMA = {};
        this.aktuellesMAJournal = {};
        this.userRoles = undefined;
    }

    get unternehmensobjekt() {
        return this.unternehmen;
    }
    
    set unternehmensobjekt(unternehmenNeu) {
        this.unternehmen = unternehmenNeu;
    }

    get monatDates() {
        const abrechnungstag = this.unternehmen.Abrechnungstag;
		let startOfMonth = window.myVars.aktuellesMonatsDatum.startOf('month');
		let endOfMonth = window.myVars.aktuellesMonatsDatum.endOf('month');
		// Wenn das Unternehmen ein Abrechnungstag früher hat, dann müssen wir die Zeiten anders anzeigen.
		// Zum Beispiel: Abrechnungstag ist er 15., dann sollte man alle Zeiten vom 16. des Vormonats bis zum 15. des aktuellen Monats anzeigen.
		if (abrechnungstag < 31) {
			startOfMonth = startOfMonth.subtract(1, 'month').add(abrechnungstag, 'days');
			endOfMonth = endOfMonth.startOf('month').add(abrechnungstag - 1, 'days');
		}
        return [startOfMonth, endOfMonth]
    }

    get aktuelleBetriebsstaette() {
		return this.aktuelleBS;
	}

	set aktuelleBetriebsstaette(betriebID) {
		this.aktuelleBS = betriebID;
	}

    get aktuellerMitarbeiter() {
        return this.aktuellerMA;
    }

    set aktuellerMitarbeiter(mitarbeiter) {
        this.aktuellerMA = mitarbeiter;
    }

    get aktuellesMitarbeiterJournal() {
        return this.aktuellesMAJournal;
    }

    set aktuellesMitarbeiterJournal(journal) {
        this.aktuellesMAJournal = journal;
    }

    get benutzerRollen() {
        return this.userRoles;
    }

    async updateMitarbeiterdaten(mitarbeiter) {
        try {
            const response = await fetch(`/neolohn/api/mitarbeiter/${mitarbeiter._id}`, {
                method: 'PUT',
                mode: 'cors',
				headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(mitarbeiter),
            });
            if (response.ok) {
                const result = await response.json();
                this.aktuellerMA = result;
                systemNachrichtService.zeigeKleineNachricht('Speichern Erfolgreich!', 1);
                window.myVars.mitarbeiterEditiert = false;
                return true;
            }
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Fehler beim Speichern!', -1);
            return false;
        }
        systemNachrichtService.zeigeKleineNachricht('Fehler beim Speichern!', -1);
        return false;
    }

    async erstelleMitarbeiter(vorname, nachname, email) {
        try {
            // Falls keine Betriebsstätte ausgewählt wurde nehmen wir die erste aktive im Unternehmen
            const betriebstaette = this.aktuelleBS ? this.aktuelleBS : this.unternehmensobjekt.Betriebsstaette.find((u) => u.Aktiv)._id;
            if (!betriebstaette) {
                systemNachrichtService.zeigeKleineNachricht('Bitte legen Sie zuerst eine aktive Betriebsstätte an!');
                return false;
            }
            const body = {
                Vorname: vorname,
                Nachname: nachname,
                Email: email,
                BetriebsstaetteID: betriebstaette
            };
            const response = await fetch(
                '/neolohn/api/mitarbeiter',
                {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(body)
                }
            );
            if (!response.ok) {
                const errorMsg = await response.json();
                const arrayErrorMsgs = errorMsg.reduce((errorStr, err) => {
                    if (errorStr === '') {
                        return err.message;
                    }
                    return `${errorStr}\n${err.message}`;
                }, '');
                systemNachrichtService.zeigeKleineNachricht(arrayErrorMsgs, 0);
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            const result = await response.json();
            this.aktuellerMA = result;
            systemNachrichtService.zeigeKleineNachricht('Mitarbeiter Erstellen Erfolgreich!', 1);
            return true;
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Fehler beim Erstellen!', -1);
            return false;
        }
    }

    async holeUnternehmensdaten(refresh = false) {
        // aktualisieren nur, falls das Unternehmen noch undefined ist oder refresht werden soll
        if (!this.unternehmen || refresh) {
            try {
                const response = await fetch('/neolohn/api/unternehmen');
                if (response.ok) {
                    const result = await response.json();
                    this.unternehmen = result;
                    // falls wir invalide Daten haben, zeigen wir dies in der Oberfläche an.
                    const verwaltungValidationSymbol = document.getElementById('Verwaltung').querySelector('i');
                    if (_.isEmpty(this.unternehmen.Errors)) {
                        verwaltungValidationSymbol.classList.add('hidden');
                    } else {
                        verwaltungValidationSymbol.classList.remove('hidden');
                    }
                }
            } catch (error) {
                console.log(error);
                return false;
            }
        }
        return this.unternehmen;
    }

    async updateUnternehmensdaten(unternehmenNeu) {
        try {
        const response = await fetch('/neolohn/api/unternehmen', {
                method: 'PUT',
                mode: 'cors',
				headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(_.omit(unternehmenNeu, 'Errors')),
            });
            if (response.ok) {
                const result = await response.json();
                this.unternehmen = result;
                systemNachrichtService.zeigeKleineNachricht('Speichern Erfolgreich!', 1);
                // falls wir invalide Daten haben, zeigen wir dies in der Oberfläche an.
                const verwaltungValidationSymbol = document.getElementById('Verwaltung').querySelector('i');
                if (_.isEmpty(this.unternehmen.Errors)) {
                    verwaltungValidationSymbol.classList.add('hidden');
                } else {
                    verwaltungValidationSymbol.classList.remove('hidden');
                }
                return true;
            }
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Fehler beim Speichern!', -1);
            return false;
        }
        systemNachrichtService.zeigeKleineNachricht('Fehler beim Speichern!', -1);
        return false;
    }

    /**
     * Post Request Logo Upload
     * @param {File} logo 
     * @returns 
     */
    async postUnternehmenslogo(logo) {
        try {
            window.myHandlers.toggleLoader();
            const response = await fetch('/neolohn/api/unternehmen/logo', {
                method: 'POST',
                body: logo
            });
            if (!response.ok) {
                const msg = await response.text();
                systemNachrichtService.zeigeKleineNachricht(msg, 0);
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            systemNachrichtService.zeigeKleineNachricht('Logo erfolgreich hochgeladen!', 1);
            window.myHandlers.toggleLoader();
            return true;
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Fehler beim Hochladen des Logos!', -1);
            window.myHandlers.toggleLoader();
            return false;
        }
    }
    /**
     * Get Request Logo
     * @returns logo
     */
    async getUnternehmenslogo() {
        try {
            window.myHandlers.toggleLoader();
            const response = await fetch('/neolohn/api/unternehmen/logo');
            if (!response.ok) {
                const msg = await response.text();
                systemNachrichtService.zeigeKleineNachricht(msg, 0);
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            const result = await response.json();
            window.myHandlers.toggleLoader();
            return result;
        } catch (error) {
            console.log(error);
            window.myHandlers.toggleLoader();
            return false;
        }
    }
    /**
     * Delete Request Logo
     */
    async deleteUnternehmenslogo() {
        try {
            window.myHandlers.toggleLoader();
            const response = await fetch('/neolohn/api/unternehmen/logo', { method: 'DELETE' });
            if (!response.ok) {
                const msg = await response.text();
                systemNachrichtService.zeigeKleineNachricht(msg, 0);
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            systemNachrichtService.zeigeKleineNachricht('Logo erfolgreich gelöscht!', 1);
            window.myHandlers.toggleLoader();
            return true;
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Logo konnte nicht gelöscht werden!', 1);
            window.myHandlers.toggleLoader();
            return false;
        }
    }

    async fetchKrankenkassenUnternehmen() {
        try {
            const response = await fetch('/neolohn/api/unternehmen/krankenkassen');
            if (!response.ok) {
                const msg = await response.text();
                systemNachrichtService.zeigeKleineNachricht(msg, 0);
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            // unternehmensobjekt refreshen
            const result = await response.json();
            this.unternehmen = result;
            return true;
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Fehler bei der Abfrage der Krankenkassen!', -1);
            return false;
        }
    }

    async erstelleBetriebsstaette(bsName) {
        try {
        const response = await fetch(`/neolohn/api/unternehmen/betriebsstaette/${encodeURIComponent(bsName)}`, {
                method: 'POST'
            });
            if (response.ok) {
                const result = await response.json();
                this.unternehmen = result;
                systemNachrichtService.zeigeKleineNachricht('Erstellen war Erfolgreich!', 1);
                return true;
            }
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Fehler beim Erstellen!', -1);
            return false;
        }
        systemNachrichtService.zeigeKleineNachricht('Fehler beim Erstellen!', -1);
        return false;
    }

    async erstelleNettoBezug(bezugBez) {
        try {
        const response = await fetch('/neolohn/api/unternehmen/netto-bezug', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ Bezeichnung: bezugBez })
            });
            if (response.ok) {
                const result = await response.json();
                this.unternehmen = result;
                systemNachrichtService.zeigeKleineNachricht('Erstellen war Erfolgreich!', 1);
                return this.unternehmen.NettoBeAbzuege.find((nba) => nba.Bezeichnung === bezugBez);
            }
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Fehler beim Erstellen!', -1);
            return false;
        }
        systemNachrichtService.zeigeKleineNachricht('Fehler beim Erstellen!', -1);
        return false;
    }

    async erstelleQualifikation(qualiNeu) {
        try {
            const response = await fetch('/neolohn/api/unternehmen/qualifikation', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(qualiNeu)
            });
            if (response.ok) {
                const result = await response.json();
                this.unternehmen = result;
                systemNachrichtService.zeigeKleineNachricht('Erstellen war Erfolgreich!', 1);
                return this.unternehmen.Qualifikationen.find((qu) => qu.BezeichnungNeutral === qualiNeu.BezeichnungNeutral);
            }
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Fehler beim Erstellen!', -1);
            return false;
        }
        systemNachrichtService.zeigeKleineNachricht('Fehler beim Erstellen!', -1);
        return false;
    }

    async erstelleEinsatzort(einsatzort, bsID) {
        try {
            const response = await fetch('/neolohn/api/unternehmen/einsatzort', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ Bezeichnung: einsatzort, BetriebsstaetteID: bsID })
                });
                if (response.ok) {
                    const result = await response.json();
                    this.unternehmen = result;
                    const bs = this.unternehmen.Betriebsstaette.find((b) => b._id === bsID);
                    systemNachrichtService.zeigeKleineNachricht('Erstellen war Erfolgreich!', 1);
                    return bs.Einsatzorte.find((eo) => eo.Bezeichnung === einsatzort);
                }
            } catch (error) {
                console.log(error);
                systemNachrichtService.zeigeKleineNachricht('Fehler beim Erstellen!', -1);
                return false;
            }
            systemNachrichtService.zeigeKleineNachricht('Fehler beim Erstellen!', -1);
            return false;
    }

    /**
     * Anfrage an den Server ein neues Pausenmodell im Unternehmen zu erstellen.
     * @param {object} pausenmodell 
     */
    async erstellePausenmodell(pausenmodell) {
        try {
            const response = await fetch('/neolohn/api/unternehmen/pausenmodell', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(pausenmodell)
            });
            if (!response.ok) {
                const message = await response.text();
                systemNachrichtService.zeigeKleineNachricht(message, 0);
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            const result = await response.json();
            // neues unternehmensobjekt speichern
            this.unternehmen = result[0];
            // neue pausenmodell id zurückgeben
            systemNachrichtService.zeigeKleineNachricht('Pausenmodell erfolgreich erstellt!', 1);
            return result[1];
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Fehler beim Erstellen des Pausenmodells', -1);
            return false;
        }
    }

    /**
     * Liest die Mitarbeiter Liste für den aktuellen zeitraum.
     * @param {string} betriebsstaetteID (optional, bei undefined unternehmensweit)
     * @param {string} zeitraum YYYY-MM-DD (aktuelle monatsauswahl)
     */
    async holeMitarbeiterListe(betriebsstaetteID, zeitraum) {
        try {
            const fetchUrl = '/neolohn/api/mitarbeiter/liste?';
            const params = new URLSearchParams();
            if (betriebsstaetteID && betriebsstaetteID !== '') {
                params.append('BetriebsstaetteID', betriebsstaetteID);
            }
            params.append('Zeitraum', zeitraum);
            const response = await fetch(fetchUrl + params.toString());
            if (!response.ok) {
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            const result = await response.json();
            result.sort((a, b) => (a.Nachname + a.Vorname).localeCompare((b.Nachname + b.Vorname)));
            return result;
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Fehler beim Laden der Mitarbeiterliste!', -1);
            return [];
        }
    }

    /**
     * Frägt das zur ID gehörige Mitarbeiterobjekt an
     * @param {string} mitarbeiterID mitarbeiterId
     * @returns mitarbeiter objekt
     */
    async holeMitarbeiter(mitarbeiterID) {
        if (!mitarbeiterID) {
            return {};
        }
        try {
            const response = await fetch(`/neolohn/api/mitarbeiter/${mitarbeiterID}`);
            if (!response.ok) {
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            const json = await response.json();
            this.aktuellerMA = json;
            return json;
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Fehler beim Laden der Mitarbeiterdaten!', -1);
            return {};
        }
    }

    /**
     * Checkt ob es noch offene Vormonate der Mitarbeiter gibt.
     * @param {array} mitarbeiterIDs array von mitarbeiterIDs
     * @param {string} zeitraum Berechnungszeitraum, z.B. 2022-10-01 für Okt. 2022
     */
    async checkVormonatMitarbeiterListe(mitarbeiterIDs, zeitraum) {
        try {
            window.myHandlers.toggleLoader();
            const response = await fetch(
                `/neolohn/api/mitarbeiter/vormonate-check?Zeitraum=${zeitraum}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        MitarbeiterIDs: mitarbeiterIDs,
                        Zeitraum: zeitraum
                    }),
                },
                );
            if (!response.ok) {
                const message = await response.text();
                systemNachrichtService.zeigeKleineNachricht(message, 0);
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            // testen, ob es invalide daten gab...
            const result = await response.json();
            window.myHandlers.toggleLoader();
            return result;
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Anfrage konnte nicht verarbeitet werden!', -1);
            window.myHandlers.toggleLoader();
            return false;
        }
    }
    /**
     * Berechnet mehrere Mitarbeiter
     * @param {array} mitarbeiterIDs array von mitarbeiterIDs
     * @param {string} zeitraum Berechnungszeitraum, z.B. 2022-10-01 für Okt. 2022
     */
    async berechneMitarbeiterListe(mitarbeiterIDs, zeitraum) {
        try {
            window.myHandlers.toggleLoader();
            if (mitarbeiterIDs.length > 20) {
                systemNachrichtService.zeigeKleineNachricht(`Berechnung von ${mitarbeiterIDs.length} Mitarbeitern, dies kann einige Sekunden dauern!`, 0);
            }
            const response = await fetch(
                `/neolohn/api/mitarbeiter/berechnung?Zeitraum=${zeitraum}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        MitarbeiterIDs: mitarbeiterIDs,
                        Zeitraum: zeitraum
                    }),
                },
                );
            if (!response.ok) {
                const message = await response.text();
                systemNachrichtService.zeigeKleineNachricht(message, 0);
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            // testen, ob es invalide daten gab...
            const result = await response.json();
            if (!_.isEmpty(result)) {
                systemNachrichtService.zeigeKleineNachricht(`${mitarbeiterIDs.length === 1 ? 'Mitarbeiter konnte nicht berechnet werden!' : 'Nicht alle Mitarbeiter konnten berechnet werden!'}`, -1);
                if (result.length < mitarbeiterIDs.length) {
                    systemNachrichtService.zeigeKleineNachricht('Berechnung teilweise erfolgreich!', 0);
                } 
                systemNachrichtService.zeigeKleineNachricht('Bitte überprüfen Sie die Mitarbeiterdaten!', 0);
            } else {
                systemNachrichtService.zeigeKleineNachricht('Berechnung erfolgreich!', 1);
            }
            // aktualisiere den aktuellen Mitarbeiter
            await this.holeMitarbeiter(this.aktuellerMA._id);
            window.myHandlers.toggleLoader();
            return true;
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Berechnung fehlgeschlagen! Bitte überprüfen Sie die Mitarbeiterdaten!', -1);
            window.myHandlers.toggleLoader();
            return false;
        }
    }

    /**
     * Berechnet mehrere Mitarbeiter
     * @param {array} mitarbeiterIDs array von mitarbeiterIDs
     * @param {string} zeitraum Berechnungszeitraum, z.B. 2022-10-01 für Okt. 2022
     */
    async exportiereMitarbeiterListe(mitarbeiterIDs, zeitraum) {
        try {
            window.myHandlers.toggleLoader();
            if (mitarbeiterIDs.length > 20) {
                systemNachrichtService.zeigeKleineNachricht(`Export von ${mitarbeiterIDs.length} Mitarbeitern, dies kann einige Sekunden dauern!`, 0);
            }
            const response = await fetch(
                '/neolohn/api/mitarbeiter/export',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        MitarbeiterIDs: mitarbeiterIDs,
                        Zeitraum: zeitraum
                    }),
                },
                );
            if (!response.ok) {
                const message = await response.text();
                systemNachrichtService.zeigeKleineNachricht(message, 0);
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            // da wir hier kein direkten Download anbieten, müssen wir das ZIP Archiv aus dem Blob zum Download anbieten
            const blob = await response.blob();
            if (blob.type === 'application/zip') {
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = 'export.zip';
                link.click();
                URL.revokeObjectURL(url);
                systemNachrichtService.zeigeKleineNachricht('Export erfolgreich!', 1);
            } else {
                systemNachrichtService.zeigeKleineNachricht('Email versendet!', 1);
            }
            window.myHandlers.toggleLoader();
            return true;
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Export fehlgeschlagen!', -1);
            window.myHandlers.toggleLoader();
            return false;
        }
    }

    /**
     * Ruft die Journaldaten eines bestimmtes monats vom Server ab
     * @param {string} mitarbeiterID ObjectID string des Mitarbeiters
     * @param {string} zeitraum Datum String im 'YYYY-MM-DD' Format
     * @returns berechnetes Mitarbeiter Objekt für einen Monat
     */
    async leseJournalMitarbeiter(mitarbeiterID, zeitraum) {
        try {
            const response = await fetch(`/neolohn/api/mitarbeiter/${mitarbeiterID}/journal?Zeitraum=${zeitraum}`);
            if (!response.ok) {
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            const result = await response.json();
            this.aktuellesMAJournal = result;
            return result;
        } catch (error) {
            console.log(error);
            return {};
        }
    }

    /**
     * Ruft alle Journaldaten eines bestimmten Mitarbeiters ab
     * @param {string} mitarbeiterID ObjectID string des Mitarbeiters
     * @returns liste von Mitarbeiter Journaldaten
     */
    async leseJournalHistorieMitarbeiter(mitarbeiterID) {
        try {
            const response = await fetch(`/neolohn/api/mitarbeiter/${mitarbeiterID}/journal/historie`);
            if (!response.ok) {
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            const result = await response.json();
            return result;
        } catch (error) {
            console.log(error);
            return {};
        }
    }

    /**
     * Sendet eine POST Request an den Server, um eine neue Mitarbeiter Nachricht anzulegen
     * @param {string} mitarbeiterID ObjectID string des Mitarbeiters
     * @param {string} nachrichtText Neuer Text der zu erstellenden Nachricht
     * @returns neues Mitarbeiterobjekt inklusive neuer Nachricht
     */
    async postMitarbeiterNachricht(mitarbeiterID, nachrichtText) {
        try {
            // Die Route liefert uns das neue Mitarbeiter Objekt [0] und die neue Nachricht [1] im Array
            const response = await fetch(`/neolohn/api/mitarbeiter/${mitarbeiterID}/nachricht`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    MitarbeiterID: mitarbeiterID,
                    NachrichtText: nachrichtText
                })
            });
            if (!response.ok) {
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            const result = await response.json();
            this.aktuellerMA = result[0];
            systemNachrichtService.zeigeKleineNachricht('Nachricht Erstellung erfolgreich!', 1);
            return result[1];
        } catch (error) {
            systemNachrichtService.zeigeKleineNachricht('Nachricht Erstellung fehlgeschlagen!', -1);
            return false;
        }
    }

    /**
     * Updated eine bereits existierende Nachricht in der Mitarbeiter Übersicht
     * @param {string} mitarbeiterID
     * @param {string} nachrichtID
     * @param {string} neueNachricht
     * @param {boolean} erledigt
     * @returns neue nachricht
     */
    async updateMitarbeiterNachricht(mitarbeiterID, nachrichtID, neueNachricht, erledigt) {
        try {
            const body = {
                MitarbeiterID: mitarbeiterID,
                NachrichtID: nachrichtID,
                NachrichtText: neueNachricht,
                Erledigt: erledigt
            };
            const response = await fetch(`/neolohn/api/mitarbeiter/${mitarbeiterID}/nachricht`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });
            if (!response.ok) {
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            const result = await response.json();
            this.aktuellerMA = result[0];
            systemNachrichtService.zeigeKleineNachricht('Nachricht erfolgreich gespeichert!', 1);
            return result[1];
        } catch (error) {
            systemNachrichtService.zeigeKleineNachricht('Nachricht Speichern fehlgeschlagen!', -1);
            return false;
        }
    }

    /**
     * Anfrage den Mitarbeiter Login zu reaktivieren
     * @param {string} mitarbeiterID
     */
    async reaktivereMitarbeiterLogin(mitarbeiterID) {
        try {
            const response = await fetch(`/neolohn/api/mitarbeiter/${mitarbeiterID}/login/reaktivieren`, { method: 'POST', });
            if (!response.ok) {
                const message = await response.text();
                systemNachrichtService.zeigeKleineNachricht(message, 0);
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            systemNachrichtService.zeigeKleineNachricht('Reaktivierung erfolgreich!', 1);
            // wir müssen das Mitarbeiter Objekt neu laden...
            await this.holeMitarbeiter(mitarbeiterID);
            return true;
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Reaktivierung fehlgeschlagen!', -1);
            return false;
        }
    }

    /**
     * Anfrage den Mitarbeiter Login zu deaktivieren
     * @param {string} mitarbeiterID
     */
    async deaktivereMitarbeiterLogin(mitarbeiterID) {
        try {
            const response = await fetch(`/neolohn/api/mitarbeiter/${mitarbeiterID}/login/deaktivieren`, { method: 'POST', });
            if (!response.ok) {
                const message = await response.text();
                systemNachrichtService.zeigeKleineNachricht(message, 0);
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            systemNachrichtService.zeigeKleineNachricht('Deaktivierung erfolgreich!', 1);
            // wir müssen das Mitarbeiter Objekt neu laden...
            await this.holeMitarbeiter(mitarbeiterID);
            return true;
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Deaktivierung fehlgeschlagen!', -1);
            return false;
        }
    }
    
    /**
     * Anfrage den Mitarbeiter Login zu reaktivieren
     * @param {string} mitarbeiterID
     */
    async reaktivereMitarbeiter(mitarbeiterID) {
        try {
            const response = await fetch(`/neolohn/api/mitarbeiter/${mitarbeiterID}/reaktivieren`, { method: 'POST', });
            if (!response.ok) {
                const message = await response.text();
                systemNachrichtService.zeigeKleineNachricht(message, 0);
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            systemNachrichtService.zeigeKleineNachricht('Reaktivierung erfolgreich!', 1);
            // wir müssen das Mitarbeiter Objekt neu laden...
            await this.holeMitarbeiter(mitarbeiterID);
            return true;
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Reaktivierung fehlgeschlagen!', -1);
            return false;
        }
    }

    /**
     * Anfrage den Mitarbeiter zu archivieren
     * @param {string} mitarbeiterID
     */
    async archiviereMitarbeiter(mitarbeiterID) {
        try {
            const response = await fetch(`/neolohn/api/mitarbeiter/${mitarbeiterID}/archivieren`, { method: 'DELETE', });
            if (!response.ok) {
                const message = await response.text();
                systemNachrichtService.zeigeKleineNachricht(message, 0);
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            systemNachrichtService.zeigeKleineNachricht('Archivierung erfolgreich!', 1);
            // wir müssen das Mitarbeiter Objekt neu laden...
            await this.holeMitarbeiter(mitarbeiterID);
            return true;
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Archivierung fehlgeschlagen!', -1);
            return false;
        }
    }

    /**
     * Anfrage den Benutzer Login zu reaktivieren
     * @param {string} userId
     */
    async reaktivereBenutzer(userId) {
        try {
            const response = await fetch(`/neolohn/api/benutzer/${userId}/reaktivieren`, { method: 'POST', });
            if (response.ok) {
                systemNachrichtService.zeigeKleineNachricht('Reaktivierung erfolgreich!', 1);
                return true;
            }
            systemNachrichtService.zeigeKleineNachricht('Reaktivierung fehlgeschlagen!', -1);
            return false;
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Reaktivierung fehlgeschlagen!', -1);
            return false;
        }
    }

    /**
     * Anfrage den Benutzer Login zu deaktivieren
     * @param {string} userId
     */
    async deaktivereBenutzer(userId) {
        try {
            const response = await fetch(`/neolohn/api/benutzer/${userId}/deaktivieren`, { method: 'POST', });
            if (response.ok) {
                systemNachrichtService.zeigeKleineNachricht('Deaktivierung erfolgreich!', 1);
                return true;
            }
            systemNachrichtService.zeigeKleineNachricht('Deaktivierung fehlgeschlagen!', -1);
            return false;
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Deaktivierung fehlgeschlagen!', -1);
            return false;
        }
    }

    /**
     * Erstellt einen neuen Bereich im Unternehmen
     * @param {string} bezeichnung
     * @param {string} nummer (Die Nummer darf Buchstaben enthalten, deswegen ein String.)
     */
    async erstelleBereich(bezeichnung, nummer) {
        try {
            const body = {
                Bezeichnung: bezeichnung,
                Nummer: nummer
            };
            const response = await fetch('/neolohn/api/unternehmen/bereich', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });
            if (response.ok) {
                systemNachrichtService.zeigeKleineNachricht('Bereichserstellung erfolgreich!', 1);
                const unternehmenNeu = await response.json();
                this.unternehmen = unternehmenNeu;
                return unternehmenNeu.Bereiche.find((b) => b.Bereich === bezeichnung);
            }
            systemNachrichtService.zeigeKleineNachricht('Fehler bei der Erstellung des Bereichs!', -1);
            return false;
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Fehler bei der Erstellung des Bereichs!', -1);
            return false;
        }
    }

    /**
     * Fetcht eine Liste an internen Mitarbeitern (optional, nur die mit extra Rollen)
     */
    async ladeInterneMitarbeiter(extraRolesOnly = false) {
        try {
            document.body.style.cursor = 'wait';
            const response = await fetch(`/neolohn/api/benutzer/intern${extraRolesOnly ? '/extra' : ''}`);
            if (response.ok) {
                const liste = await response.json();
                document.body.style.cursor = 'auto';
                return liste;
            }
            document.body.style.cursor = 'auto';
            return false;
        } catch (error) {
            console.log(error);
            document.body.style.cursor = 'auto';
            return false;
        }
    }

    /**
     * Fetcht einen bestimmten internen Mitarbeiter
     */
    async ladeInternerMitarbeiterUser(mitarbeiterID) {
        try {
            document.body.style.cursor = 'wait';
            const response = await fetch(`/neolohn/api/mitarbeiter/${mitarbeiterID}/user`);
            if (response.ok) {
                const liste = await response.json();
                document.body.style.cursor = 'auto';
                return liste;
            }
            document.body.style.cursor = 'auto';
            return false;
        } catch (error) {
            console.log(error);
            document.body.style.cursor = 'auto';
            return false;
        }
    }

    /**
     * Fetcht eine Liste an externen Mitarbeitern
     */
    async ladeExterneMitarbeiter() {
        try {
            const response = await fetch('/neolohn/api/benutzer/extern');
            if (response.ok) {
                const liste = await response.json();
                return liste;
            }
            return false;
        } catch (error) {
            console.log(error);
            return false;
        }
    }

    /**
     * Gibt eine Liste der möglichen Benutzer Rollen zurück
     * @param {boolean} refresh (optimaler Weise, laden wir die liste nur einmal)
     * @returns liste an benutzer rollen
     */
    async ladeBenutzerRollen(refresh = false) {
        if (!this.userRoles || refresh) {
            try {
                const response = await fetch('/neolohn/api/benutzer/rollen');
                if (response.ok) {
                    const liste = await response.json();
                    this.userRoles = liste;
                    return this.userRoles;
                }
                return false;
            } catch (error) {
                console.log(error);
                return false;
            }
        }
        return this.userRoles;
    }

    /**
     * Anfrage, dem User eine neue Rolle zuzuweisen...
     * @param {string} role Rolle die neu hinzugefügt werden soll
     * @param {string} userId
     */
    async addBenutzerRolle(role, userId) {
        try {
            const body = {
                Role: role
            };
            const response = await fetch(`/neolohn/api/benutzer/${userId}/rollen/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });
            if (response.ok) {
                systemNachrichtService.zeigeKleineNachricht('Benutzerrollen erfolgreich aktualisiert!', 1);
                return true;
            }
            systemNachrichtService.zeigeKleineNachricht('Fehler beim Aktualisieren der Benutzerrollen!', -1);
            return false;
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Fehler beim Aktualisieren der Benutzerrollen!', -1);
            return false;
        }
    }

    /**
     * Anfrage dem User eine Rolle zu entnehmen
     * @param {string} role Rolle die neu hinzugefügt werden soll
     * @param {string} userId
     */
    async removeBenutzerRolle(role, userId) {
        try {
            const body = {
                Role: role
            };
            const response = await fetch(`/neolohn/api/benutzer/${userId}/rollen/remove`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });
            if (response.ok) {
                systemNachrichtService.zeigeKleineNachricht('Benutzerrollen erfolgreich aktualisiert!', 1);
                return true;
            }
            systemNachrichtService.zeigeKleineNachricht('Fehler beim Aktualisieren der Benutzerrollen!', -1);
            return false;
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Fehler beim Aktualisieren der Benutzerrollen!', -1);
            return false;
        }
    }
   
    /**
     * Anfrage, dem User eine neuen Bereich zuzuweisen...
     * @param {string} bereichId Bereich der neu hinzugefügt werden soll
     * @param {string} userId
     */
    async addBenutzerBereich(bereichId, userId) {
        try {
            const body = {
                Bereich: bereichId
            };
            const response = await fetch(`/neolohn/api/benutzer/${userId}/bereiche/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });
            if (response.ok) {
                systemNachrichtService.zeigeKleineNachricht('Benutzerbereiche erfolgreich aktualisiert!', 1);
                return true;
            }
            systemNachrichtService.zeigeKleineNachricht('Fehler beim Aktualisieren der Benutzerbereiche!', -1);
            return false;
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Fehler beim Aktualisieren der Benutzerbereiche!', -1);
            return false;
        }
    }

    /**
     * Anfrage dem User einen Bereich zu entnehmen
     * @param {string} bereichId Bereich der neu hinzugefügt werden soll
     * @param {string} userId
     */
    async removeBenutzerBereich(bereichId, userId) {
        try {
            const body = {
                Bereich: bereichId
            };
            const response = await fetch(`/neolohn/api/benutzer/${userId}/bereiche/remove`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });
            if (response.ok) {
                systemNachrichtService.zeigeKleineNachricht('Benutzerbereiche erfolgreich aktualisiert!', 1);
                return true;
            }
            systemNachrichtService.zeigeKleineNachricht('Fehler beim Aktualisieren der Benutzerbereiche!', -1);
            return false;
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Fehler beim Aktualisieren der Benutzerbereiche!', -1);
            return false;
        }
    }

    /**
     * Aktualisiert die zugehörige Betriebsstätte eines Users.
     * @param {string} betriebId id der Betriebsstätte
     * @param {string} userId 
     */
    async updateBenutzerBetrieb(betriebId, userId) {
        try {
            const body = {
                Betrieb: betriebId
            };
            const response = await fetch(`/neolohn/api/benutzer/${userId}/betriebe`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });
            if (response.ok) {
                systemNachrichtService.zeigeKleineNachricht('Benutzerbetrieb erfolgreich aktualisiert!', 1);
                return true;
            }
            systemNachrichtService.zeigeKleineNachricht('Fehler beim Aktualisieren des Benutzerbetriebs!', -1);
            return false;
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Fehler beim Aktualisieren des Benutzerbetriebs!', -1);
            return false;
        }
    }

    async postExternalUser(userdata) {
        try {
            const response = await fetch('/neolohn/api/benutzer/extern', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(userdata),
            });
            if (!response.ok) {
                const message = await response.text();
                systemNachrichtService.zeigeKleineNachricht(message, 0);
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            systemNachrichtService.zeigeKleineNachricht('Benutzer erfolgreich angelegt!', 1);
            return true;
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Fehler beim Anlegen des Benutzers!', -1);
            return false;
        }
    }

    /**
     * Erstellt ein neuen Posten in der Betriebsstätte
     * @param {string} bezeichnung
     * @param {string} bereich ID des zugehörigen Bereichs
     * @param {string} nummer
     * @param {string} betriebsstaetteID
     * @returns
     */
    async erstellePosten(bezeichnung, bereich, nummer, betriebsstaetteID) {
        try {
            const body = {
                Bezeichnung: bezeichnung,
                BereichID: bereich,
                Nummer: nummer,
                BetriebsstaetteID: betriebsstaetteID
            };
            const response = await fetch('/neolohn/api/unternehmen/posten', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });
            if (response.ok) {
                systemNachrichtService.zeigeKleineNachricht('Postenerstellung erfolgreich!', 1);
                const unternehmenNeu = await response.json();
                this.unternehmen = unternehmenNeu;
                const betrieb = unternehmenNeu.Betriebsstaette.find((bs) => _.isEqual(bs._id, betriebsstaetteID));
                return betrieb.Posten.find((p) => p.Posten === bezeichnung);
            }
            systemNachrichtService.zeigeKleineNachricht('Fehler bei der Erstellung des Posten!', -1);
            return false;
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Fehler bei der Erstellung des Posten!', -1);
            return false;
        }
    }

    /**
     * Sendet die Anfrage der Sofortmeldung eines Mitarbeiters an neolohn
     * @param {string} mitarbeiterID
     */
    async postSofortmeldungMitarbeiter(mitarbeiterID) {
        try {
            SystemDialogService.instance.display('ma-sofortmeldung-loading-dialog', { closeOnBackdrop: false });
            const response = await fetch(`/neolohn/api/mitarbeiter/${mitarbeiterID}/sofortmeldung`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    MitarbeiterID: mitarbeiterID,
                })
            });
            await this.holeMitarbeiter(mitarbeiterID);
            if (!response.ok) {
                // Bei einer Bad Request erwarten wir eine Liste an Fehlern
                    SystemDialogService.instance.confirm();
                if (response.status === 400) {
                    const errorList = await response.json();
                    systemNachrichtService.zeigeKleineNachricht('Fehler bei der Sofortmeldung!', -1);
                    return errorList;
                }
                if (response.status === 503) {
                    return [{ message: 'Sofortmelder zur Zeit nicht erreichbar!' }];
                }
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            systemNachrichtService.zeigeKleineNachricht('Sofortmeldung erfolgreich!', 1);
            systemNachrichtService.zeigeKleineNachricht('Neue PDF im Mitarbeiter Postfach!', 1);
            SystemDialogService.instance.confirm();
            const result = await response.json();
            return result;
        } catch (error) {
            console.log(error);
            await this.holeMitarbeiter(mitarbeiterID);
            systemNachrichtService.zeigeKleineNachricht('Fehler bei der Sofortmeldung!', -1);
            SystemDialogService.instance.confirm();
            return false;
        }
    }

    /**
     * Sendet die Anfrage der Sofortmeldung eines Mitarbeiters an neolohn
     * @param {string} mitarbeiterID
     */
    async postStornierungSofortmeldungMitarbeiter(mitarbeiterID) {
        try {
            // Wir zeigen ein Ladebalken Dialog an, der sich erst nach Abschluss der Anfrage schließt.
            SystemDialogService.instance.display('ma-sofortmeldung-loading-dialog', { closeOnBackdrop: false });
            const response = await fetch(`/neolohn/api/mitarbeiter/${mitarbeiterID}/sofortmeldung/storno`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    MitarbeiterID: mitarbeiterID,
                })
            });
            await this.holeMitarbeiter(mitarbeiterID);
            if (!response.ok) {
                if (response.status === 503) {
                    systemNachrichtService.zeigeKleineNachricht('Sofortmelder zur Zeit nicht erreichbar!', -1);
                }
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            systemNachrichtService.zeigeKleineNachricht('Sofortmeldung erfolgreich!', 1);
            systemNachrichtService.zeigeKleineNachricht('Neue PDF im Mitarbeiter Postfach!', 1);
            SystemDialogService.instance.confirm();
            const result = await response.json();
            return result;
        } catch (error) {
            console.log(error);
            await this.holeMitarbeiter(mitarbeiterID);
            systemNachrichtService.zeigeKleineNachricht('Fehler bei der Sofortmeldung!', -1);
            SystemDialogService.instance.confirm();
            return false;
        }
    }

    /**
     * Stellt die Anfrage an den Server einen Bericht für den Mitarbeiter zu generieren.
     * @param {string} mitarbeiterIDs
     * @param {string} zeitraum Date string im YYYY-MM-DD Format
     * @param {string} bericht bestimmt welche Art von Bericht erstellt werden soll
     */
    async downloadBerichteMA(mitarbeiterIDs, zeitraum, bericht) {
        try {
            window.myHandlers.toggleLoader();
            const body = {
                MitarbeiterIDs: mitarbeiterIDs,
                Zeitraum: zeitraum,
                CurrentSort: getCurrentSortListe()
            };
            // bei der wochenübersicht brauchen wir noch die betriebsstätte und die kalenderwoche als datum
            if (bericht === 'wochenuebersicht') {
                body.BetriebsstaetteID = document.getElementById('menu-betriebsstaette').value;
                /**
                 * Der übergebene zeitraum ist immer die erste KW des monats.
                 * Die Differenz des KW Inputs mit der ersten KW des Monats ist >= 0
                 * Wir addieren die Differenz der KWs auf das Datum des Zeitraums.
                 */
                const inputKW = document.getElementById('report-kw').value;
                const diffKW = inputKW - dayjs(zeitraum).isoWeek();
                const zeitraumKW = dayjs(zeitraum).add(diffKW, 'weeks');
                body.Zeitraum = zeitraumKW.format('YYYY-MM-DD');
            }
            if (['personalkosten', 'stundenbereiche', 'bereichsauswertung', 'einsatzortauswertung'].includes(bericht)) {
                body.BetriebsstaetteID = document.getElementById('menu-betriebsstaette').value;
            }
            const response = await fetch(`/neolohn/api/mitarbeiter/${bericht}/pdf`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });
            if (!response.ok) {
                const message = await response.text();
                systemNachrichtService.zeigeKleineNachricht(message, 0);
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            // da wir hier kein direkten Download anbieten, müssen wir das ZIP Archiv aus dem Blob zum Download anbieten
            const blob = await response.blob();
            if (blob.type === 'application/zip') {
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = `${response.headers.get('Download-Filename') ? response.headers.get('Download-Filename') : `${bericht}.zip`}`;
                document.getElementById('ma-Berichte').appendChild(link);
                link.click();
                URL.revokeObjectURL(url);
                link.remove();
                window.myHandlers.toggleLoader();
                systemNachrichtService.zeigeKleineNachricht('Download erfolgreich!', 1);
                return true;
            }
        } catch (error) {
            console.log(error);
            window.myHandlers.toggleLoader();
            systemNachrichtService.zeigeKleineNachricht('Fehler bei der Erstellung des Downloads!', -1);
            return false;
        }
    }

    /**
     * Stellt die Anfrage an den Server einen Bericht für den Mitarbeiter zu generieren.
     * @param {string} mitarbeiterIDs
     * @param {string} zeitraum Date string im YYYY-MM-DD Format
     */
    async postBerichteMA(mitarbeiterIDs, zeitraum, bericht) {
        try {
            const body = {
                MitarbeiterIDs: mitarbeiterIDs,
                Zeitraum: zeitraum
            };
            // bei der wochenübersicht brauchen wir noch die betriebsstätte und die kalenderwoche als datum
            if (bericht === 'wochenuebersicht') {
                body.BetriebsstaetteID = document.getElementById('menu-betriebsstaette').value;
                /**
                 * Der übergebene zeitraum ist immer die erste KW des monats.
                 * Die Differenz des KW Inputs mit der ersten KW des Monats ist >= 0
                 * Wir addieren die Differenz der KWs auf das Datum des Zeitraums.
                 */
                const inputKW = document.getElementById('report-kw').value;
                const diffKW = inputKW - dayjs(zeitraum).isoWeek();
                const zeitraumKW = dayjs(zeitraum).add(diffKW, 'weeks');
                body.Zeitraum = zeitraumKW.format('YYYY-MM-DD');
            }
            if (['personalkosten', 'stundenbereiche', 'bereichsauswertung', 'einsatzortauswertung'].includes(bericht)) {
                body.BetriebsstaetteID = document.getElementById('menu-betriebsstaette').value;
            }
            const response = await fetch(`/neolohn/api/mitarbeiter/${bericht}/html`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });
            if (!response.ok) {
                const message = await response.text();
                systemNachrichtService.zeigeKleineNachricht(message, 0);
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            const result = await response.json();
            if (_.isEmpty(result)) {
                systemNachrichtService.zeigeKleineNachricht('Keine Journaldaten vorhanden!');
                return false;
            }
            return result;
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Fehler bei der Erstellung des Berichts!', -1);
            return false;
        }
    }

    /**
     * Stellt die Anfrage an den Server einen Bericht für den Mitarbeiter zu generieren.
     * @param {array<String>} mitarbeiterIDs
     * @param {string} zeitraum Date string im YYYY-MM-DD Format
     * @param {string} bericht gewünschter bericht typ
     */
    async postBerichteCSV(mitarbeiterIDs, zeitraum, bericht) {
        try {
            window.myHandlers.toggleLoader();
            const body = {
                Zeitraum: zeitraum,
                MitarbeiterIDs: mitarbeiterIDs,
                ZeitraumVon: '',
                ZeitraumBis: ''
            };
            // CSV Berichte mit variablen Zeitraum
            if ([berichteTypen.Stundenliste].includes(bericht)) {
                const monatDates = this.monatDates;
                document.getElementById('berichte-zeitraum-von').value = monatDates[0].format('YYYY-MM-DD');
                document.getElementById('berichte-zeitraum-bis').value = monatDates[1].format('YYYY-MM-DD');
                const input = await SystemDialogService.instance.displayAsync('berichte-zeitraum-dialog');
                if (!input.success) {
                    window.myHandlers.toggleLoader();
                    return;
                }
                body.ZeitraumVon = input.data.ZeitraumVon;
                body.ZeitraumBis = input.data.ZeitraumBis;
            }
            // CSV Berichte mit variablen Monaten
            if ([berichteTypen.Monatskosten].includes(bericht)) {
                document.getElementById('berichte-zeitraum-monate-von').value = window.myVars.aktuellesMonatsDatum.format('YYYY-MM');
                document.getElementById('berichte-zeitraum-monate-bis').value = window.myVars.aktuellesMonatsDatum.format('YYYY-MM');
                const input = await SystemDialogService.instance.displayAsync('berichte-zeitraum-monate-dialog');
                if (!input.success) {
                    window.myHandlers.toggleLoader();
                    return;
                }
                // von YYYY-MM input Format zu YYYY-MM-DD Format wechslen
                body.ZeitraumVon = `${input.data.ZeitraumVon}-01`;
                body.ZeitraumBis = `${input.data.ZeitraumBis}-01`;
                body.Zeitraum = body.ZeitraumVon;
            }
            const response = await fetch(`/neolohn/api/mitarbeiter/${bericht}/csv`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });
            if (!response.ok) {
                const message = await response.text();
                systemNachrichtService.zeigeKleineNachricht(message, 0);
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            // da wir hier kein direkten Download anbieten, müssen wir das CSV aus dem Blob zum Download anbieten
            const blob = await response.blob();
            if (blob.type === 'text/csv' || blob.type === 'text/csv; charset=utf-8' || blob.type === 'application/zip') {
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = decodeURIComponent(response.headers.get('Download-Filename'));
                document.getElementById('ma-Berichte').appendChild(link);
                link.click();
                URL.revokeObjectURL(url);
                link.remove();
                window.myHandlers.toggleLoader();
                systemNachrichtService.zeigeKleineNachricht('Erfolgreich erstellt!', 1);
                return true;
            }
            window.myHandlers.toggleLoader();
        } catch (error) {
            console.log(error);
            window.myHandlers.toggleLoader();
            systemNachrichtService.zeigeKleineNachricht('Fehler bei der Erstellung der CSV Datei!', -1);
            return false;
        }
    }

    /**
     * Entsperrt das Mitarbeiter Journalobekt für den gewählten Zeitraum
     * @param {string} mitarbeiterID 
     * @param {string} zeitraum Date string im YYYY-MM-DD Format
     */
    async entsperreJournalMA(mitarbeiterID, zeitraum) {
        try {
            window.myHandlers.toggleLoader();
            const body = {
                MitarbeiterID: mitarbeiterID,
                Zeitraum: zeitraum,
            };
            const response = await fetch(`/neolohn/api/mitarbeiter/${mitarbeiterID}/journal/entsperren`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });
            if (!response.ok) {
                const message = await response.text();
                systemNachrichtService.zeigeKleineNachricht(message, 0);
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            const result = await response.json();
            systemNachrichtService.zeigeKleineNachricht('Monat erfolgreich entsperrt!', 1);
            window.myHandlers.toggleLoader();
            return result;
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Entsperren fehlgeschlagen!', -1);
            window.myHandlers.toggleLoader();
            return false;
        }
    }
    /**
     * Entsperrt das Mitarbeiter Journalobekt für den gewählten Zeitraum
     * @param {string} zeitraum Date string im YYYY-MM-DD Format
     * @param {string} betriebstaettteID aktueller Betrieb 
     */
    async entsperreJournaleAlleMA(zeitraum, betriebstaettteID) {
        try {
            window.myHandlers.toggleLoader();
            const body = {
                Zeitraum: zeitraum,
                BetriebsstaetteID: betriebstaettteID
            };
            const response = await fetch('/neolohn/api/mitarbeiter/journal/entsperren', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });
            if (!response.ok) {
                const message = await response.text();
                systemNachrichtService.zeigeKleineNachricht(message, 0);
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            systemNachrichtService.zeigeKleineNachricht('Monat erfolgreich entsperrt!', 1);
            window.myHandlers.toggleLoader();
            return true;
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Entsperren fehlgeschlagen!', -1);
            window.myHandlers.toggleLoader();
            return false;
        }
    }

    /**
     * Erstellt das Mitarbeiter Vorwert Journalobekt für den gewählten Zeitraum
     * @param {string} mitarbeiterID 
     * @param {string} zeitraum Date string im YYYY-MM-DD Format
     * @param {number} egfzSchnittwert
     */
    async erstelleJournalVorwertMA(mitarbeiterID, zeitraum, egfzSchnittwert, agKostenSchnittwert, arbeitsstunden, arbeitstage) {
        try {
            window.myHandlers.toggleLoader();
            const body = {
                MitarbeiterID: mitarbeiterID,
                Zeitraum: zeitraum,
                EgfzSchnittwert: egfzSchnittwert,
                AGKostenSchnittwert: agKostenSchnittwert,
                Arbeitsstunden: arbeitsstunden,
                Arbeitstage: arbeitstage
            };
            const response = await fetch(`/neolohn/api/mitarbeiter/${mitarbeiterID}/journal/vorwert`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });
            if (!response.ok) {
                const message = await response.text();
                systemNachrichtService.zeigeKleineNachricht(message, 0);
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            const result = await response.json();
            systemNachrichtService.zeigeKleineNachricht('Vormonatswerte wurden erfasst!', 1);
            // aktualisiere den aktuellen Mitarbeiter für mögliche neue Übersichtswerte
            await this.holeMitarbeiter(this.aktuellerMA._id);
            window.myHandlers.toggleLoader();
            return result;
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Vormonatswerte Anlegen fehlgeschlagen!', -1);
            window.myHandlers.toggleLoader();
            return false;
        }
    }

    /**
     * Erstellt das Mitarbeiter Vorwert Journalobekt für den gewählten Zeitraum
     * @param {string} mitarbeiterID 
     * @param {string} zeitraum Date string im YYYY-MM-DD Format
     */
    async entferneJournalVorwertMA(mitarbeiterID, zeitraum) {
        try {
            window.myHandlers.toggleLoader();
            const params = new URLSearchParams();
            params.append('Zeitraum', zeitraum);
            const response = await fetch(`/neolohn/api/mitarbeiter/${mitarbeiterID}/journal/vorwert?${params.toString()}`, {
                method: 'DELETE'
            });
            if (!response.ok) {
                const message = await response.text();
                systemNachrichtService.zeigeKleineNachricht(message, 0);
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            systemNachrichtService.zeigeKleineNachricht('Vormonatseintrag wurde entfernt!', 1);
            // aktualisiere den aktuellen Mitarbeiter für mögliche neue Übersichtswerte
            await this.holeMitarbeiter(this.aktuellerMA._id);
            window.myHandlers.toggleLoader();
            return true;
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Vormonatseintrag Entfernen fehlgeschlagen!', -1);
            window.myHandlers.toggleLoader();
            return false;
        }
    }

    /**
     * Server Anfrage für das kopieren eines Mitarbeiterobjekts bei Beschäftigungswechsel
     * @param {string} mitarbeiterID 
     * @param {object} optionen 
     */
    async kopiereMitarbeiterBeschaeftigungswechsel(mitarbeiterID, optionen) {
        try {
            window.myHandlers.toggleLoader();
            const body = { MitarbeiterID: mitarbeiterID, Optionen: optionen };
            const response = await fetch(`/neolohn/api/mitarbeiter/${mitarbeiterID}/beschaeftigungswechsel`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });
            if (!response.ok) {
                const message = await response.text();
                systemNachrichtService.zeigeKleineNachricht(message, 0);
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            const result = await response.json();
            systemNachrichtService.zeigeKleineNachricht('Mitarbeiter erfolgreich kopiert!', 1);
            window.myHandlers.toggleLoader();
            return result;
        } catch (error) {
            window.myHandlers.toggleLoader();
            systemNachrichtService.zeigeKleineNachricht('Kopieren fehlgeschlagen!', -1);
            return false;
        }
    }

    /**
     * Sendet eine Server Request für die Erstellung eines neues Verzeichnisses durch.
     * @param {string} verzeichnisName 
     */
    async erstellePostfachVerzeichnis(verzeichnisName) {
        try {
            window.myHandlers.toggleLoader();
            const body = {
                VerzeichnisName: verzeichnisName
            };
            const response = await fetch('/neolohn/api/unternehmen/verzeichnisse', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });
            if (!response.ok) {
                const message = await response.text();
                systemNachrichtService.zeigeKleineNachricht(message, 0);
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            const result = await response.json();
            this.unternehmen = result;
            systemNachrichtService.zeigeKleineNachricht('Verzeichnis erfolgreich erstellt!', 1);
            window.myHandlers.toggleLoader();
            return true;
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Verzeichnis Erstellung fehlgeschlagen!', -1);
            window.myHandlers.toggleLoader();
            return false;
        }
    }

    /**
     * Sendet eine Server Request für die Entfernung eines Verzeichnisses durch.
     * @param {string} verzeichnisID 
     */
    async entfernePostfachVerzeichnis(verzeichnisID) {
        try {
            window.myHandlers.toggleLoader();
            const response = await fetch(`/neolohn/api/unternehmen/verzeichnisse/${verzeichnisID}`, { method: 'DELETE' });
            if (!response.ok) {
                const message = await response.text();
                systemNachrichtService.zeigeKleineNachricht(message, 0);
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            const result = await response.json();
            this.unternehmen = result;
            systemNachrichtService.zeigeKleineNachricht('Verzeichnis erfolgreich entfernt!', 1);
            window.myHandlers.toggleLoader();
            return true;
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Verzeichnis konnte nicht entfernt werden!', -1);
            window.myHandlers.toggleLoader();
            return false;
        }
    }

    /**
     * Sendet eine Server Request für das Anlegen einer Lohnartengruppe
     * @param {*} lohnartengruppe 
     * @returns neues Unternehmensobjekt
     */
    async erstelleLohnartengruppe(lohnartengruppe) {
        try {
            window.myHandlers.toggleLoader();
            const response = await fetch('/neolohn/api/unternehmen/lohnartengruppe', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(lohnartengruppe)
            });
            if (!response.ok) {
                const message = await response.text();
                systemNachrichtService.zeigeKleineNachricht(message, 0);
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            const result = await response.json();
            this.unternehmen = result;
            systemNachrichtService.zeigeKleineNachricht('Lohnartengruppe erstellt!', 1);
            window.myHandlers.toggleLoader();
            return true;
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Lohnartengruppe konnte nicht erstellt werden!', -1);
            window.myHandlers.toggleLoader();
            return false;
        }
    }

    /**
     * Sendet eine Server Request für das Anlegen eines Entbindungstermins
     * @param {string} mitarbeiterID 
     * @param {object} entbidungstermin 
     */
    async erstelleEntbindungsterminMA(mitarbeiterID, entbidungstermin) {
        try {
            window.myHandlers.toggleLoader();
            const response = await fetch(`/neolohn/api/mitarbeiter/${mitarbeiterID}/entbindungstermin`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(entbidungstermin)
            });
            if (!response.ok) {
                const message = await response.text();
                systemNachrichtService.zeigeKleineNachricht(message, 0);
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            const result = await response.json();
            this.aktuellerMA = result;
            systemNachrichtService.zeigeKleineNachricht('Entbindungstermin vermerkt', 1);
            window.myHandlers.toggleLoader();
            return true;
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Fehler bei der Anfrage!', -1);
            window.myHandlers.toggleLoader();
            return false;
        }
    }
    /**
     * Sendet eine Server Request für das Anlegen eines Entbindungstermins
     * @param {string} mitarbeiterID 
     * @param {object} entbidungsterminID
     */
    async entferneEntbindungsterminMA(mitarbeiterID, entbidungsterminID) {
        try {
            window.myHandlers.toggleLoader();
            const response = await fetch(`/neolohn/api/mitarbeiter/${mitarbeiterID}/entbindungstermin/${entbidungsterminID}`, {
                method: 'DELETE',
            });
            if (!response.ok) {
                const message = await response.text();
                systemNachrichtService.zeigeKleineNachricht(message, 0);
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            const result = await response.json();
            this.aktuellerMA = result;
            systemNachrichtService.zeigeKleineNachricht('Entbindungstermin erfolgreich entfernt', 1);
            window.myHandlers.toggleLoader();
            return true;
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Fehler bei der Anfrage!', -1);
            window.myHandlers.toggleLoader();
            return false;
        }
    }

    /**
     * Anlegen einer neuen Urlaubssperre im Unternehmen
     * @param {*} urlaubssperre 
     */
    async neueUrlaubssperre(urlaubssperre) {
        try {
            window.myHandlers.toggleLoader();
            const response = await fetch('/neolohn/api/unternehmen/urlaubssperre', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(urlaubssperre)
            });
            if (!response.ok) {
                const message = await response.text();
                systemNachrichtService.zeigeKleineNachricht(message, 0);
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            const result = await response.json();
            this.unternehmensobjekt = result;
            systemNachrichtService.zeigeKleineNachricht('Urlaubssperre aktiviert!', 1);
            window.myHandlers.toggleLoader();
            return true;
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Fehler bei der Anfrage!', -1);
            window.myHandlers.toggleLoader();
            return false;
        }
    }
    
    /**
     * Entfernen einer neuen Urlaubssperre im Unternehmen
     * @param {*} urlaubssperre 
     */
    async entferneUrlaubssperre(urlaubssperreID) {
        try {
            window.myHandlers.toggleLoader();
            const response = await fetch(`/neolohn/api/unternehmen/urlaubssperre/${urlaubssperreID}`, {
                method: 'DELETE',
            });
            if (!response.ok) {
                const message = await response.text();
                systemNachrichtService.zeigeKleineNachricht(message, 0);
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            const result = await response.json();
            this.unternehmensobjekt = result;
            systemNachrichtService.zeigeKleineNachricht('Urlaubssperre aktiviert!', 1);
            window.myHandlers.toggleLoader();
            return true;
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Fehler bei der Anfrage!', -1);
            window.myHandlers.toggleLoader();
            return false;
        }
    }

    async holeBewerberliste(betriebsstaetteID) {
        try {
            const fetchUrl = '/neolohn/api/mitarbeiter/bewerbungen?';
            const params = new URLSearchParams();
            if (betriebsstaetteID && betriebsstaetteID !== '') {
                params.append('BetriebsstaetteID', betriebsstaetteID);
            }
            const response = await fetch(fetchUrl + params.toString());
            if (!response.ok) {
                const message = await response.text();
                systemNachrichtService.zeigeKleineNachricht(message, 0);
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            const result = await response.json();
            result.sort((a, b) => a.Nachname.localeCompare(b.Nachname));
            return result;
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Fehler beim Laden der Bewerbungen!', -1);
            return [];
        }
    }
    
    async holeBewerberung(bewerberID) {
        try {
            const fetchUrl = `/neolohn/api/mitarbeiter/bewerbungen/${bewerberID}`;
            const response = await fetch(fetchUrl);
            if (!response.ok) {
                const message = await response.text();
                systemNachrichtService.zeigeKleineNachricht(message, 0);
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            const result = await response.json();
            return result;
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Fehler beim Laden der Bewerbung!', -1);
            return [];
        }
    }

    async ablehnenUrlaubsantrag(mitarbeiterID, urlaubID, statusUpdate = 'abgelehnt') {
        try {
            const response = await fetch('/neolohn/api/mitarbeiter/urlaub/urlaubsantrag', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    MitarbeiterID: mitarbeiterID,
                    UrlaubID: urlaubID,
                    StatusUpdate: statusUpdate
                })
            });
            if (!response.ok) {
                const message = await response.text();
                systemNachrichtService.zeigeKleineNachricht(message, 0);
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            systemNachrichtService.zeigeKleineNachricht('Urlaubsantrag erfolgreich abgelehnt!', 1);
            return true;
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Fehler beim Ablehnen des Urlaubs!', -1);
            return false;
        }
    }
    
    /**
     * Löscht eine Datei endgültig aus dem Papierkorb
     * @param {string} dokumentID ID der Datei
     */
    async entferneDatei(dokumentID) {
        try {
            const response = await fetch(`/neolohn/api/dokument/${dokumentID}`, {
                method: 'DELETE',
            });
            if (!response.ok) {
                const msg = await response.text();
                systemNachrichtService.zeigeKleineNachricht(msg);
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            systemNachrichtService.zeigeKleineNachricht('Löschen der Datei erfolgreich!', 1);
            return true;
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Fehler beim Löschen der Datei!', -1);
            return false;
        }
    }

    async holeBeschaeftigungsWechselAnfrage(mitarbeiterID) {
        try {
            const response = await fetch(`/neolohn/api/mitarbeiter/${mitarbeiterID}/beschaeftigungswechsel`);
            if (!response.ok) {
                const message = await response.text();
                systemNachrichtService.zeigeKleineNachricht(message, 0);
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            const result = await response.json();
            return result;
        } catch (error) {
            console.log(error);
            return false;
        }
    }
    
    async widerrufeBeschaeftigungsWechselAnfragen(mitarbeiterID) {
        try {
            const response = await fetch(`/neolohn/api/mitarbeiter/${mitarbeiterID}/beschaeftigungswechsel`, { method: 'DELETE' });
            if (!response.ok) {
                const message = await response.text();
                systemNachrichtService.zeigeKleineNachricht(message, 0);
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            systemNachrichtService.zeigeKleineNachricht('Geplanter Beschäftigungswechsel wurde erfolgreich widerrufen!', 1);
            return true;
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Fehler beim Widerrufen des geplanten Beschäftigungswechsels!', -1);
            return false;
        }
    }
}

const stammdatenService = new StammdatenService();
export default stammdatenService;
