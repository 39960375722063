import _ from 'underscore';
import dayjs from '../../../../shared/services/dayjs.js';
import SystemDialogService from '../../../../shared/services/systemDialogService.js';
import basedataService from '../services/basedataService.js';
import systemNachrichtService from '../services/systemNachrichtService.js';
import { debounce, downloadLinkTab, erstelleOptionGroup, toggleLoader } from '../util.js';
import stammdatenService from '../services/stammdatenService.js';
import { updateDebounceUnternehmen } from './verwaltung.js';

const debounceSpeichereVorlage = debounce(async (args) => {
	await speichereVorlage(args[0]);
}, 1000);

window.myHandlers = window.myHandlers || {};
window.myHandlers.confirmVorlageNeuDialog = confirmVorlageNeuDialog;
window.myHandlers.speichereVorlage = speichereVorlage;
window.myHandlers.debounceSpeichereVorlage = debounceSpeichereVorlage;
window.myHandlers.setzeVorlageNeuName = setzeVorlageNeuName;
window.myHandlers.neueVorlage = neueVorlage;
window.myHandlers.editDocName = editDocName;
window.myHandlers.toggleHeader = toggleHeader;
window.myHandlers.neuerUnternehmensordner = neuerUnternehmensordner;
window.myHandlers.confirmOrdnerNeuDialog = confirmOrdnerNeuDialog;
window.myHandlers.uploadUnternehmenDatei = uploadUnternehmenDatei;
window.myHandlers.loescheUnternehmenDokument = loescheUnternehmenDokument;

async function ladeSchriftverkehr() {
	await ladeUnternehmensordner();
	dragAndDropVorlagen();
	await holeVorlagen();
	await holeMustervorlagen();
	await holeFesteTextmarken();
	await holeTextmarken();
}

async function holeMustervorlagen() {
	const vorlagen = await basedataService.holeVorlagenBasedataProvider();
	const sortedVorlagen = vorlagen.sort((a, b) => (a.Name.toUpperCase().localeCompare(b.Name.toUpperCase())));
	const liste = document.querySelector('#schriftverkehr-mustervorlagen');
	liste.innerHTML = '';
	sortedVorlagen.forEach((item) => {
		const newItem = erstelleMustervorlagenElement(item);
		liste.appendChild(newItem);
	});
}

/**
 * Erstellt oder Updated ein Vorlagendokument
 * - Bei der Erstellung wird zusätzlich nach einem Dateinamen gefragt.
 */
 async function speichereVorlage(nameChange = false) {
	// eine leere File speichern wir nicht.
	if (_.isEmpty(window.myServices.editorService.getCurrentFile())) {
		return;
	}
	try {
		const mdContent = window.myServices.editorService.getMarkdown();
		// Nur Updaten falls es noch Änderungen gibt.
		const oldObject = window.myServices.editorService.getCurrentFile();
		if (oldObject.Inhalt !== mdContent || nameChange) {
			oldObject.Inhalt = mdContent;
			const response = await fetch('/neolohn/api/vorlage', {
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
				mode: 'cors',
				body: JSON.stringify(oldObject),
			});
			if (!response.ok) {
				if (response.status === 413) {
					systemNachrichtService.zeigeKleineNachricht('Vorlagedatei zu groß, bitte nur kleine Bilder (weniger als 100KB) einfügen!', -1);
					return;
				}
				systemNachrichtService.zeigeKleineNachricht('Speichern fehlgeschlagen!', -1);
				return;
			}
			const result = await response.json();
			window.myServices.editorService.setCurrentFile(result);
			systemNachrichtService.zeigeKleineNachricht('Speichern erfolgreich!', 1);
			await holeVorlagen(result._id);
		}
	} catch (error) {
		console.log(error);
		systemNachrichtService.zeigeKleineNachricht('Speichern fehlgeschlagen!', -1);
	}
}

/**
 * markiert die aktuelle Auswahl für bessere Übersicht.
 */
function aktiveVorlageMarkieren(elementId) {
	const items = document.querySelectorAll('.dokument-item-active');
	items.forEach((elem) => elem.classList.remove('dokument-item-active'));
	const auswahl = document.getElementById(elementId);
	if (!auswahl) {
		return;
	}
	auswahl.classList.add('dokument-item-active');
}

/**
 * Lädt die Vorlagen Dokumente vom Server und zeigt sie in der Liste an.
 */
async function holeVorlagen(aktivesElementId) {
	try {
		const response = await fetch('/neolohn/api/vorlage');
		if (response.ok) {
			const result = await response.json();
			const sortedVorlagen = result.sort((a, b) => (a.Name.toUpperCase().localeCompare(b.Name.toUpperCase())));
			const liste = document.querySelector('#schriftverkehr-dokumente-liste');
			const papierkorb = document.querySelector('#schriftverkehr-papierkorb-liste');
			liste.innerHTML = '';
			papierkorb.innerHTML = '';
			sortedVorlagen.forEach((item) => {
				const newItem = erstelleVorlagenElement(item);
				item.InPapierkorb ? papierkorb.appendChild(newItem) : liste.appendChild(newItem);
			});
		}
		if (aktivesElementId) {
			aktiveVorlageMarkieren(aktivesElementId);
		}
	} catch (error) {
		console.log(error);
		systemNachrichtService.zeigeKleineNachricht('Laden von Dokumenten fehlgeschlagen!', -1);
	}
}

/**
 * Liest ein einzelnes Vorlagen Objekt aus.
 * @param {string} vorlageID
 * @returns vorlagenobjekt
 */
async function fetchVorlage(vorlageID) {
	try {
		const response = await fetch(`/neolohn/api/dokument/md/${vorlageID}`);
		if (!response.ok) {
			throw new Error(`HTTP error, status = ${response.status}`);
		}
		const result = await response.json();
		return result;
	} catch (error) {
		console.log(error);
		systemNachrichtService.zeigeKleineNachricht('Fehler beim Laden der Vorlage!', -1);
		return false;
	}
}

/**
 * Erstellt das HTML Element der Vorlage in der Liste der Vorlagen inklusive Event Handler.
 * @param {object} vorlageObjekt
 */
function erstelleVorlagenElement(vorlageObjekt) {
	const template = document.querySelector('[schriftverkehr-item-template]');
	const newItem = template.content.cloneNode(true).children[0];
	newItem.querySelector('.vorlage-name').innerText = `${vorlageObjekt.Name}`;
	newItem.querySelector('.vorlage-info').innerText = ` (${dayjs(vorlageObjekt.DatumUpdate).format('DD.MM.YYYY, HH:mm')})`;
	newItem.id = vorlageObjekt._id;
	newItem.setAttribute('draggable', true);
	newItem.addEventListener('click', async () => {
		// Speichert die alte ausgewählte Vorlage, beim Wechsel in eine andere
		await speichereVorlage();
		window.myServices.editorService.setMarkdown(vorlageObjekt.Inhalt);
		window.myServices.editorService.setCurrentFile(vorlageObjekt);
		document.getElementById('schriftverkehr-filename-edit').value = vorlageObjekt.Name;
		aktiveVorlageMarkieren(newItem.id);
		document.getElementById('editor').style.visibility = 'visible';
		// editierbarer Inhalt
		const editor = document.getElementById('editor');
		editor.style.visibility = 'visible';
		editor.classList.remove('uneditable');
		editor.querySelector('.ProseMirror.toastui-editor-contents').setAttribute('contenteditable', 'true');
		document.getElementById('schriftverkehr-filename-edit').disabled = false;
	});
	return newItem;
}

/**
 * Erstellt das HTML Element der Vorlage in der Liste der Vorlagen inklusive Event Handler.
 * @param {object} vorlageObjekt
 */
function erstelleMustervorlagenElement(vorlageObjekt) {
	const template = document.querySelector('[schriftverkehr-item-template]');
	const newItem = template.content.cloneNode(true).children[0];
	newItem.innerText = vorlageObjekt.Name;
	newItem.id = vorlageObjekt._id;
	newItem.addEventListener('click', async () => {
		// Speichert die alte ausgewählte Vorlage, beim Wechsel in eine andere
		await speichereVorlage();
		window.myServices.editorService.setMarkdown(vorlageObjekt.Inhalt);
		window.myServices.editorService.setCurrentFile(vorlageObjekt);
		document.getElementById('schriftverkehr-filename-edit').value = vorlageObjekt.Name;
		aktiveVorlageMarkieren(newItem.id);
		// uneditierbarer Inhalt
		const editor = document.getElementById('editor');
		editor.style.visibility = 'visible';
		editor.classList.add('uneditable');
		editor.querySelector('.ProseMirror.toastui-editor-contents').setAttribute('contenteditable', 'false');
		document.getElementById('schriftverkehr-filename-edit').disabled = true;
	});
	return newItem;
}

// Neues Dokument soll erstellt werden... dafür wird der Editor Inhalt resettet
async function neueVorlage() {
	await speichereVorlage();
	await fuelleVorlagenSelect();
	const input = await SystemDialogService.instance.displayAsync('vorlage-speichern-dialog');
	if (!input.success) {
		return;
	}
	const name = input.data.name === '' ? 'unbenannte Vorlage' : input.data.name;
	const vorlageID = input.data.vorlageID;
	let mdContent = '';
	// wenn eine Vorlage ausgewählt wurde müssen wir zwischen Mustervorlage und eigener Vorlage unterscheiden.
	if (!_.isEmpty(vorlageID)) {
		const select = document.querySelector('#vorlage-neu-vorlagen');
		const isMustervorlage = select.selectedOptions[0].parentElement.label === 'Mustervorlagen';
		let vorlagenObjekt;
		if (isMustervorlage) {
			const mustervorlagen = await basedataService.holeVorlagenBasedataProvider();
			vorlagenObjekt = mustervorlagen.find((vorlage) => vorlage._id === vorlageID);
		} else {
			vorlagenObjekt = await fetchVorlage(vorlageID);
		}
		if (!_.isEmpty(vorlagenObjekt)) {
			mdContent = vorlagenObjekt.Inhalt;
		}
	}
	const vorlageNeu = await fetchNeueVorlage(name, mdContent);
	window.myServices.editorService.setCurrentFile(vorlageNeu);
	window.myServices.editorService.setMarkdown(vorlageNeu.Inhalt);
	document.getElementById('schriftverkehr-filename-edit').value = vorlageNeu.Name;
	// editierbarer Inhalt
	const editor = document.getElementById('editor');
	editor.style.visibility = 'visible';
	editor.classList.remove('uneditable');
	document.getElementById('schriftverkehr-filename-edit').disabled = false;
	editor.querySelector('.ProseMirror.toastui-editor-contents').setAttribute('contenteditable', 'true');
	systemNachrichtService.zeigeKleineNachricht('Speichern erfolgreich!', 1);
	// neu laden der Dokumente bei Erstellung, um das neue anzuzeigen
	await holeVorlagen(vorlageNeu._id);
	aktiveVorlageMarkieren();
}

/**
 * Sendet die fetch POST Anfrage an den Server, um eine neue Vorlage zu erstellen
 * @param {string} vorlageName
 * @param {string} vorlageInhalt Markdown Content.
 * @returns neues Vorlagenobjekt
 */
async function fetchNeueVorlage(vorlageName, vorlageInhalt) {
	try {
		const response = await fetch('/neolohn/api/vorlage', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			mode: 'cors',
			body: JSON.stringify({ Inhalt: vorlageInhalt, Name: vorlageName }),
		});
		if (!response.ok) {
			if (response.status === 413) {
				systemNachrichtService.zeigeKleineNachricht('Vorlagedatei zu groß, bitte nur kleine Bilder (weniger als 100KB) einfügen!', -1);
			}
			throw new Error(`HTTP error, status = ${response.status}`);
		}
		const result = await response.json();
		return result;
	} catch (error) {
		console.log(error);
		systemNachrichtService.zeigeKleineNachricht('Speichern fehlgeschlagen!', -1);
		return false;
	}
}

/**
 * Setzt den Input Namen auf den Vorlagennamen.
 */
function setzeVorlageNeuName(thisElement) {
	if (thisElement.value === '') {
		return;
	}
	const inputName = document.querySelector('#vorlage-neu-name');
	const selectedName = thisElement.selectedOptions[0].innerText;
	inputName.value = `${selectedName} (Kopie)`;
	inputName.focus();
}

/**
 * Click Handler: Übermittlung der Daten für die neue Vorlage aus dem Dialog Feld
 */
async function confirmVorlageNeuDialog() {
	SystemDialogService.instance.confirm({
		name: document.getElementById('vorlage-neu-name').value,
		vorlageID: document.getElementById('vorlage-neu-vorlagen').value
	});
}

/**
 * Lädt die Vorlagen Dokumente vom Server und zeigt sie in dem Select an.
 */
async function fuelleVorlagenSelect() {
	try {
        const select = document.querySelector('#vorlage-neu-vorlagen');
        select.innerHTML = '';
		const response = await fetch('/neolohn/api/vorlage');
		if (!response.ok) {
            const result = await response.text();
            systemNachrichtService.zeigeKleineNachricht(result, 0);
            throw new Error(`HTTP error, status = ${response.status}`);
        }
		const emptyOption = document.createElement('option');
		emptyOption.value = '';
		emptyOption.innerText = 'keine Auswahl';
		select.appendChild(emptyOption);
        const listeEigeneVorlagen = await response.json();
        // Option Gruppe Eigene Vorlagen
        if (listeEigeneVorlagen.length > 0) {
            listeEigeneVorlagen.sort((a, b) => a.Name.localeCompare(b.Name));
            select.appendChild(erstelleOptionGroup('eigene Vorlagen', listeEigeneVorlagen, '_id', 'Name'));
        }
        const listeStandardVorlagen = await basedataService.holeVorlagenBasedataProvider();
        // Option Gruppe Mustervorlagen
        if (listeStandardVorlagen.length > 0) {
            listeStandardVorlagen.sort((a, b) => a.Name.localeCompare(b.Name));
            select.appendChild(erstelleOptionGroup('Mustervorlagen', listeStandardVorlagen, '_id', 'Name'));
        }
	} catch (error) {
		console.log(error);
		systemNachrichtService.zeigeKleineNachricht('Laden von Vorlagen fehlgeschlagen!', -1);
	}
}

// Fügt den Inhalt der Variable ein in den Editor
function addVariable(textmarke) {
	const editor = document.getElementById('editor');
	// falls wir im nicht edit modus sind, brechen wir ab.
	if (editor.classList.contains('uneditable')) {
		return;
	}
	window.myServices.editorService.replaceCurrentSelection(` ${textmarke} `);
	debounceSpeichereVorlage();
}

// Lädt die Textmarken in die Oberfläche
async function holeTextmarken() {
	try {
		const response = await fetch('/neolohn/api/textmarken-basedataprovider');
		if (response.ok) {
			const result = await response.json();
			const textmarken = result.filter((tm) => tm.Collection !== 'Entlohnungsart');
			const template = document.querySelector('[schriftverkehr-item-template]');
			const liste = document.querySelector('#schriftverkehr-variablen-flex');
			liste.innerHTML = '';
			textmarken.sort((a, b) => (a.Textmarke.toUpperCase().localeCompare(b.Textmarke.toUpperCase())));
			textmarken.forEach((item) => {
				const newItem = template.content.cloneNode(true).children[0];
				newItem.innerText = item.Textmarke.replaceAll(/(\{|\})/g, '');
				newItem.innerText = item.Textmarke;
				newItem.id = item._id;
				newItem.addEventListener('click', () => {
					addVariable(item.Textmarke);
				});
				liste.appendChild(newItem);
			});
		}
	} catch (error) {
		console.log(error);
		systemNachrichtService.zeigeKleineNachricht('Laden von Variablen fehlgeschlagen!', -1);
	}
}

// Lädt die Textmarken in die Oberfläche
async function holeFesteTextmarken() {
	try {
		const response = await fetch('/neolohn/api/festetextmarken-basedataprovider');
		if (response.ok) {
			const result = await response.json();
			const template = document.querySelector('[schriftverkehr-item-template]');
			const liste = document.querySelector('#schriftverkehr-variablen-fest');
			liste.innerHTML = '';
			result.sort((a, b) => (a.Textmarke.toUpperCase().localeCompare(b.Textmarke.toUpperCase())));
			result.forEach((item) => {
				const newItem = template.content.cloneNode(true).children[0];
				newItem.innerText = item.Textmarke.replaceAll(/(\{|\})/g, '');
				newItem.id = item._id;
				newItem.addEventListener('click', () => {
					addVariable(item.Textmarke);
				});
				liste.appendChild(newItem);
			});
		}
	} catch (error) {
		console.log(error);
		systemNachrichtService.zeigeKleineNachricht('Laden von Variablen fehlgeschlagen!', -1);
	}
}

async function editDocName(thisElement) {
	const currentFile = window.myServices.editorService.getCurrentFile();
	if (_.isEmpty(currentFile) || thisElement.value === '') {
		return;
	}
	window.myServices.editorService.setFileName(thisElement.value);
	document.getElementById(currentFile._id).innerText = `${thisElement.value} (${dayjs(currentFile.DatumUpdate).format('DD.MM.YYYY, HH:mm')})`;
	debounceSpeichereVorlage(true);
}

function toggleHeader(thisHeader) {
	thisHeader.classList.toggle('closed-header');
	thisHeader.nextElementSibling.classList.toggle('hidden');
	// Vorlagen Button auch unsichtbar machen
	if (!thisHeader.nextElementSibling.nextElementSibling) return;
	if (thisHeader.nextElementSibling.nextElementSibling.classList.contains('schriftverkehr-header2') 
		|| thisHeader.nextElementSibling.nextElementSibling.classList.contains('schriftverkehr-header3')) {
		return;
	}
	thisHeader.nextElementSibling.nextElementSibling.classList.toggle('hidden');
}

async function ladeUnternehmensordner() {
	const unternehmen = await stammdatenService.holeUnternehmensdaten(true);
	if (_.isEmpty(unternehmen?.SchriftverkehrOrdner)) return;
	// Liste Reset
	const ordnerListe = document.getElementById('schriftverkehr-uploads');
	ordnerListe.innerHTML = '';
	const ordnerTemplate = document.body.querySelector('[schriftverkehr-ordner-template]');
	// Jeden Ordner anzeigen...
	unternehmen?.SchriftverkehrOrdner.forEach((ordner) => {
		const ordnerNeu = ordnerTemplate.content.cloneNode(true);
		const ordnerHeader = ordnerNeu.querySelector('.schriftverkehr-ordner');
		ordnerHeader.id = ordner._id;
		ordnerHeader.children[1].innerText = ordner.OrdnerName;
		// beim Click auf Ordner öffnen Dokumente Laden...
		ordnerHeader.addEventListener('click', async () => {
			toggleHeader(ordnerHeader);
			// Dokumente Laden falls der Ordner geöffnet wurde
			if (!ordnerHeader.classList.contains('closed-header')) {
				// FETCH ORDNER DOKUMENTE
				await fetchOrdnerDokumente(ordner._id);
			}
		});
		ordnerHeader.addEventListener('contextmenu', async (event) => {
			event.preventDefault();
			await umbennenOrdner(ordner);
		});
		addDragAndDropEvents(ordnerHeader);
		ordnerListe.appendChild(ordnerNeu);
	});
}

async function umbennenOrdner(ordner) {
	const dialog = document.getElementById('ordner-speichern-dialog');
	document.getElementById('ordner-neu-name').value = ordner.OrdnerName;
	const input = await SystemDialogService.instance.displayAsync(dialog.id);
	if (input.success) {
		const unternehmen = await stammdatenService.holeUnternehmensdaten(true);
		const ordnername = input.data.ordnername;
		const ordnerU = unternehmen.SchriftverkehrOrdner.find((o) => o._id == ordner._id);
		if (ordnerU) {
			ordnerU.OrdnerName = ordnername;
		}
		updateDebounceUnternehmen(unternehmen);
		const ordnerElement = document.getElementById(ordner._id);
		ordnerElement.children[1].innerText = ordnername;
	}
}

async function neuerUnternehmensordner() {
	const dialog = document.getElementById('ordner-speichern-dialog');
	const result = await SystemDialogService.instance.displayAsync(dialog.id);
	if (result.success) {
		const ordnername = result.data.ordnername;
		await fetchNeuerOrdnerUnternehmen(ordnername);
		await ladeUnternehmensordner();
	}
}

async function fetchNeuerOrdnerUnternehmen(ordnername) {
	try {
		toggleLoader();
		const response = await fetch('/neolohn/api/unternehmen/ordner', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				OrdnerName: ordnername
			})
		});
		if (!response.ok) {
            const result = await response.text();
            systemNachrichtService.zeigeKleineNachricht(result, 0);
            throw new Error(`HTTP error, status = ${response.status}`);
        }
		systemNachrichtService.zeigeKleineNachricht('Ordner erfolgreich erstellt!', 1);
		toggleLoader();
		return true;
	} catch (error) {
		console.log(error);
		toggleLoader();
		systemNachrichtService.zeigeKleineNachricht('Fehler bei der Anfrage!', -1);
		return false;
	}
}

/**
 * Rendert die Liste an Dokumenten im Unternehmensordner
 * @param {*} ordnerID 
 */
async function fetchOrdnerDokumente(ordnerID) {
	try {
		document.body.style.setProperty('cursor', 'progress');
		const response = await fetch(`/neolohn/api/unternehmen/ordner/${ordnerID}/dokumente`);
		if (!response.ok) {
			const result = await response.text();
            systemNachrichtService.zeigeKleineNachricht(result, 0);
            throw new Error(`HTTP error, status = ${response.status}`);
        }
		const result = await response.json();
		const sortedDocs = result.sort((a, b) => (a.Name.toUpperCase().localeCompare(b.Name.toUpperCase())));
		const ordnerHTML = document.getElementById(ordnerID);
		const liste = ordnerHTML.nextElementSibling;
		liste.innerHTML = '';
		sortedDocs.forEach((item) => {
			const newItem = erstelleDokumentElement(item);
			liste.appendChild(newItem);
		});
		document.body.style.setProperty('cursor', 'unset');
	} catch (error) {
		console.log(error);
		systemNachrichtService.zeigeKleineNachricht('Dokumente konnten nicht geladen werden!', -1);
		document.body.style.setProperty('cursor', 'unset');
	}
}

/**
 * Erstellt das HTML Element des Dokuments in der Liste der Dokumente eines Ordners inklusive Event Handler.
 * @param {object} dokumentObjekt
 */
function erstelleDokumentElement(dokumentObjekt) {
	const template = document.querySelector('[dokument-item-template]');
	const newItem = template.content.cloneNode(true).children[0];
	newItem.id = dokumentObjekt._id;
	const itemName = newItem.querySelector('.dokument-name');
    itemName.innerText = dokumentObjekt.Name;
    itemName.title = dokumentObjekt.Name;
    const itemInfo = newItem.querySelector('.dokument-info');
    itemInfo.querySelector('[aria-label="dokument-UserUpdate"]').innerText = dokumentObjekt.UserUpdate || dokumentObjekt.UserErstellt;
    const datum = dokumentObjekt.DatumUpdate || dokumentObjekt.DatumErstellt;
    itemInfo.querySelector('[aria-label="dokument-DatumUpdate"]').innerText = dayjs(datum).format('DD.MM.YY HH:mm');
	const docIcon = newItem.querySelector('.dokument-icon');
	// je nach Dateityp, laden wir ein Icon
    switch (dokumentObjekt.MimeType) {
        case 'application/pdf':
            docIcon.innerHTML = '<i class="bi bi-file-pdf-fill"></i>';
            break;
        case 'image/png':
        case 'image/jpg':
		case 'image/jpeg':
		case 'image/tiff':
            docIcon.innerHTML = '<i class="bi bi-file-image-fill"></i>';
            break;
        case 'application/vnd.oasis.opendocument.text':
        case 'application/msword':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            docIcon.innerHTML = '<i class="bi bi-file-earmark-arrow-down-fill"></i>';
            break;
		case 'application/vnd.ms-excel':
		case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
		case 'text/csv':
			docIcon.innerHTML = '<i class="bi bi-file-earmark-spreadsheet-fill"></i>';
			break;
        default:
            break;
    }

	newItem.addEventListener('click', async () => {
		// erstmal nur download...
		const docUrl = `/neolohn/api/dokument/${dokumentObjekt._id}/${dokumentObjekt.Name}`; 
		downloadLinkTab(docUrl, dokumentObjekt.Name);
	});
	return newItem;
}


function confirmOrdnerNeuDialog() {
	if (document.getElementById('ordner-neu-name').value.length < 1) {
		return systemNachrichtService.zeigeKleineNachricht('Bitte Namen eingeben!');
	}
	SystemDialogService.instance.confirm({
		ordnername: document.getElementById('ordner-neu-name').value
	});
}

// TODO
function dragAndDropVorlagen() {
	const start = ['dragover', 'dragenter'];
    const end = ['dragleave', 'dragend'];
	const eigeneVorlagen = document.getElementById('eigene-vorlagen');
	const papierkorbVorlagen = document.getElementById('papierkorb-vorlagen');
	[eigeneVorlagen, papierkorbVorlagen].forEach((ordnerElement) => {
		start.forEach((dragEvent) => {
			ordnerElement.addEventListener(dragEvent, (event) => {
				event.preventDefault();
				ordnerElement.classList.add('drop-zone-dragover');
			});
		});
		end.forEach((dragEvent) => {
			ordnerElement.addEventListener(dragEvent, (event) => {
				event.preventDefault();
				ordnerElement.classList.remove('drop-zone-dragover');
			});
		});
	
		ordnerElement.addEventListener('drop', async (e) => {
			e.preventDefault();
			ordnerElement.classList.remove('drop-zone-dragover');
			// Falls wir eine Datei als HTML Element verschieben, müssen wir die DokumentID und neue VerzeichnisID angeben
			if (e.dataTransfer.getData('vorlageID') !== '') {
				const vorlageID = e.dataTransfer.getData('vorlageID');
				const papierkorb = ordnerElement.id === 'papierkorb-vorlagen';
				await verschiebeVorlage(vorlageID, papierkorb);
				await holeVorlagen(vorlageID);
				return;
			}
		});
	});
}

/**
 * Fügt die Drag and Drop Events dem Verzeichnis hinzu
 * - markiert das Verzeichnis, falls es per Drag überflogen wird
 * - unmarkiert es, falls es nicht mehr überflogen wird
 * - ladet die Datei hoch falls die Datei gedropped wird ins Verzeichnis
 * @param {HTMLElement} ordnerElement
 */
function addDragAndDropEvents(ordnerElement, allowUploads = true) {
    const start = ['dragover', 'dragenter'];
    const end = ['dragleave', 'dragend'];
    start.forEach((dragEvent) => {
        ordnerElement.addEventListener(dragEvent, (event) => {
            event.preventDefault();
            ordnerElement.classList.add('drop-zone-dragover');
        });
    });
    end.forEach((dragEvent) => {
        ordnerElement.addEventListener(dragEvent, (event) => {
            event.preventDefault();
            ordnerElement.classList.remove('drop-zone-dragover');
        });
    });

    ordnerElement.addEventListener('drop', async (e) => {
        e.preventDefault();
        ordnerElement.classList.remove('drop-zone-dragover');
        // Falls wir eine Datei als HTML Element verschieben, müssen wir die DokumentID und neue VerzeichnisID angeben
        if (e.dataTransfer.getData('docID') !== '') {
            const docID = e.dataTransfer.getData('docID');
            const neuesVerzeichnis = ordnerElement.id;
            await verschiebeUnternehmenDatei(neuesVerzeichnis, docID);
            document.getElementById(docID).remove();
            await fetchOrdnerDokumente(neuesVerzeichnis);
            return;
        }
        // Falls wir richtige Dateien hochladen...
        if (allowUploads) {
            const input = ordnerElement.querySelector('.input-file-upload');
            input.files = e.dataTransfer.files;
            await uploadUnternehmenDatei(ordnerElement);
        }
    });
}

/**
 * Verschiebt eine HTML Datei in ein anders Verzeichnis
 * @param {string} verzeichnisNeu ID des neuen Verzeichnisses
 * @param {string} dokumentID ID der Datei
 */
async function verschiebeUnternehmenDatei(verzeichnisNeu, dokumentID) {
    try {
        const body = {
            DokumentID: dokumentID,
            VerzeichnisID: verzeichnisNeu
        };
        const response = await fetch('/neolohn/api/dokument/verschieben', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });
        if (!response.ok) {
			const result = await response.text();
            systemNachrichtService.zeigeKleineNachricht(result, 0);
            throw new Error(`HTTP error, status = ${response.status}`);
		}
		systemNachrichtService.zeigeKleineNachricht('Verschieben der Datei erfolgreich!', 1);
    } catch (error) {
        console.log(error);
        systemNachrichtService.zeigeKleineNachricht('Fehler beim Verschieben der Datei!', -1);
    }
}

/**
 * Lädt die aktuelle Datei per fetch und formData hoch
 * @param {HTMLElement} thisElement Ordner Element
 */
async function uploadUnternehmenDatei(thisElement) {
	// input form ist im Header div vesteckt.
    const inputForm = thisElement.querySelector('form');
    const ordnerID = thisElement.id;
    const file = new FormData(inputForm);
    const allowedDataFormats = [
        'application/pdf',
        'image/png',
        'image/jpg',
        'image/jpeg',
        'application/vnd.oasis.opendocument.text',
        'application/msword',
		'application/vnd.ms-excel',
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'image/tiff',
		'text/csv'
    ];
    if (!allowedDataFormats.includes(file.get('file-upload').type)) {
        systemNachrichtService.zeigeKleineNachricht('Nur .png, .jpg, .pdf, .odt, .doc, .csv, .docx, .xlsx, .tiff Dateien sind erlaubt!');
        return;
    }
    file.append('OrdnerID', ordnerID); // das landet im body...
    try {
        const response = await fetch(`/neolohn/api/unternehmen/ordner/${ordnerID}/dokumente`, {
            method: 'POST',
            body: file
        });
        if (!response.ok) {
            const msg = await response.text();
            systemNachrichtService.zeigeKleineNachricht(msg);
            throw new Error(`HTTP error, status = ${response.status}`);
        }
		// Wir öffnen die Liste, falls noch geschlossen...
		const ordnerHTML = document.getElementById(ordnerID);
		if (ordnerHTML.classList.contains('closed-header')) toggleHeader(ordnerHTML);
        await fetchOrdnerDokumente(ordnerID);
        systemNachrichtService.zeigeKleineNachricht('Upload erfolgreich!', 1);
    } catch (error) {
        console.log(error);
        systemNachrichtService.zeigeKleineNachricht('Upload fehlgeschlagen!', -1);
    }
}

async function loescheUnternehmenDokument(thisElement, event) {
	try {
		// Verhindere, dass der Klick den download auslöst
		event.stopPropagation();
		const dokumentHTML = thisElement.closest('.postfach-item');
		const dateiName = document.getElementById('datei-loeschen-name');
		dateiName.innerText = dokumentHTML.querySelector('.dokument-name').innerText;
		const input = await SystemDialogService.instance.displayAsync('datei-loeschen-dialog');
		if (!input.success) return;
		const response = await fetch(`/neolohn/api/dokument/${dokumentHTML.id}`, {
			method: 'DELETE'
		});
		if (!response.ok) {
            const msg = await response.text();
            systemNachrichtService.zeigeKleineNachricht(msg);
            throw new Error(`HTTP error, status = ${response.status}`);
        }
		dokumentHTML.remove();
		systemNachrichtService.zeigeKleineNachricht('Datei gelöscht!', 1);
	} catch (error) {
		console.log(error);
		systemNachrichtService.zeigeKleineNachricht('Fehler beim Löschen der Datei!', -1);
	}
}

/**
 * Verschiebt eine HTML Datei in ein anders Verzeichnis
 * @param {string} vorlageID ID der Datei
 * @param {Boolean} inPapierkorb entscheidung ob papierkorb oder eigene vorlagen
 */
async function verschiebeVorlage(vorlageID, inPapierkorb) {
    try {
        const body = {
            VorlageID: vorlageID,
            InPapierkorb: inPapierkorb
        };
        const response = await fetch(`/neolohn/api/vorlage/${vorlageID}/verschieben`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });
        if (!response.ok) {
			const result = await response.text();
            systemNachrichtService.zeigeKleineNachricht(result, 0);
            throw new Error(`HTTP error, status = ${response.status}`);
		}
		systemNachrichtService.zeigeKleineNachricht('Verschieben der Vorlage erfolgreich!', 1);
    } catch (error) {
        console.log(error);
        systemNachrichtService.zeigeKleineNachricht('Fehler beim Verschieben der Vorlage!', -1);
    }
}

export {
    ladeSchriftverkehr,
	holeFesteTextmarken,
	holeTextmarken,
	holeVorlagen,
};